import { useQuery } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  OrderLookupFilterOrderTransactionTypeEnum,
  OrderLookupFilterOrderTypeEnum,
  OrderLookupFilterSortEnum,
  OrderState,
  SearchOrdersDocument,
  SearchOrdersQuery,
  SearchOrdersQueryVariables,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { OrdersIcon } from '@sitoo/mui-components'
import { PaymentStateRenderer } from './../../orders/orders-list/payment-state'
import { TotalRenderer } from './../../orders/orders-list/total'
import { useMemo, useCallback, useContext } from 'react'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { useDayJs } from '../../../hooks/day-js'
import { Button } from '@mui/material'
import { ExchangeFilterEnum } from './exchange-filter'
import { CustomerDetailTypeEnum } from './customer-filter'
import { PaymentValidated } from './payment-validated-filter'
import { FilterContext } from '../../../components/data-grid/context'
import { IdType } from '../../orders/orders-list/order-id-filter'
import { DEFAULT_ORDER_ID } from '../../../utils/constants'
import { OrderAdditionalData } from '../../orders/util'
import { PaymentsRenderer } from '../../orders/orders-list/payments-renderer'
import { DateTimeRenderer } from '../../../components/data-grid/utils/date-time-renderer'
import { BulkActions } from '../../orders/orders-list/bulk-actions'
import { usePaymentMethod } from './use-payment-method'
import { useMe } from '../../../hooks/me'

type Row = ArrayElement<SearchOrdersQuery['searchOrders']['items']>

type OrderListProps = {
  orderState?: OrderState
  onDetailOrder(orderId: number): void
  currentDetailedOrderId?: number
  onShowFilter(): void
}

export const MAX_ITEMS_ORDER_API = 10

export const OrderSearchList = (props: OrderListProps) => {
  const { t } = useTranslation(['orders', 'shared'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { me } = useMe()
  const apiRef = useGridApiRef()

  const dayJs = useDayJs()

  const sortItems = useMemo<SortItem<OrderLookupFilterSortEnum>[]>(
    () => [
      {
        field: 'orderdate',
        sort: 'asc',
        title: t('orders:date_time'),
        type: 'number',
        value: OrderLookupFilterSortEnum.Asc,
        isDefault: true,
      },
      {
        field: 'orderdate',
        sort: 'desc',
        title: t('orders:date_time'),
        type: 'number',
        value: OrderLookupFilterSortEnum.Desc,
      },
    ],
    [t],
  )

  const columnVisibilityModel = useMemo(
    () => ({
      datereserved: false,
      paymentstateid: false,
      'custom-customer': false,
      orderdatetime: false,
    }),
    [],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const { getPaymentMethods } = usePaymentMethod()

  // Stabilize the date between the re-renders
  const getNowDateString = useCallback(() => {
    return dayJs().utc().endOf('minute').toJSON()
  }, [dayJs])

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        pageSize: apiRef.current.state?.pagination.paginationModel.pageSize,
        start: 0,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: SearchOrdersQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (
      Array.isArray(config.filter?.['id']?.value) &&
      config.filter?.['id']?.value.length === 2
    ) {
      const [type, value] = config.filter?.['id']?.value as [
        IdType,
        string | string[],
      ]

      if (type === IdType.OrderId) {
        variables.orderid = Array.isArray(value)
          ? value.map((x) => Number(x) || DEFAULT_ORDER_ID)
          : Number(value) || DEFAULT_ORDER_ID
      }

      if (type === IdType.ExternalId) {
        variables.externalid = value
      }

      if (type === IdType.ReceiptId) {
        variables.receiptid = Array.isArray(value) ? value?.[0] : value
      }

      if (type === IdType.CreditOrderId) {
        variables.creditorderid =
          (Array.isArray(value) ? Number(value?.[0]) : Number(value)) ||
          DEFAULT_ORDER_ID
      }

      return variables
    }

    if (typeof config.filter?.['date']?.value === 'string') {
      variables.dateFrom = config.filter['date']?.value
      variables.dateTo = getNowDateString()
    } else if (Array.isArray(config.filter?.['date']?.value)) {
      const value = config.filter?.['date']?.value

      if (typeof value?.[0] === 'string') {
        variables.dateFrom = value[0]
      }
      if (typeof value?.[1] === 'string') {
        variables.dateTo = value[1]
      }
    }

    if (Array.isArray(config.filter?.['staffId']?.value)) {
      variables.staffExternalId = config.filter?.['staffId']?.value
    }

    if (Array.isArray(config.filter?.['storeId']?.value)) {
      variables.storeIds = config.filter?.['storeId']?.value
    }

    if (Array.isArray(config.filter?.['registerId']?.value)) {
      variables.registerIds = config.filter?.['registerId']?.value
    }

    if (Array.isArray(config.filter?.['paymentAmount']?.value)) {
      const value = config.filter?.['paymentAmount']?.value

      if (typeof value?.[0] === 'string' && value[0]) {
        variables.paymentAmountMin = value[0]
      }
      if (typeof value?.[1] === 'string' && value[1]) {
        variables.paymentAmountMax = value[1]
      }
    }

    if (Array.isArray(config.filter?.['orderAmount']?.value)) {
      const value = config.filter?.['orderAmount']?.value

      if (typeof value?.[0] === 'string' && value[0]) {
        variables.totalAmountMin = value[0]
      }
      if (typeof value?.[1] === 'string' && value[1]) {
        variables.totalAmountMax = value[1]
      }
    }

    if (typeof config.filter?.['cardNumber']?.value === 'string') {
      variables.paymentCardMaskedPan = config.filter['cardNumber'].value
    }

    if (
      config.filter?.['cardIssuer']?.value &&
      Array.isArray(config.filter?.['cardIssuer']?.value) &&
      config.filter?.['cardIssuer']?.value.length > 0
    ) {
      variables.paymentCardIssuer = config.filter?.['cardIssuer']?.value
    }

    if (typeof config.filter?.['paymentValidated']?.value === 'string') {
      const paymentValidated = config.filter?.['paymentValidated']
        ?.value as PaymentValidated

      if (paymentValidated === PaymentValidated.Validated) {
        variables.paymentValidated = ['+']
      }
      if (paymentValidated === PaymentValidated.NotValidated) {
        variables.paymentValidated = ['-']
      }
    }

    if (Array.isArray(config.filter?.['paymentMethod']?.value)) {
      variables.paymentMethod = getPaymentMethods(
        config.filter['paymentMethod'].value,
      )
    }

    if (typeof config.filter?.['transactionType']?.value === 'string') {
      variables.orderTransactionType = config.filter?.['transactionType']
        ?.value as OrderLookupFilterOrderTransactionTypeEnum
    }

    if (typeof config.filter?.['exchange']?.value === 'string') {
      variables.paymentVirtual =
        (config.filter?.['exchange']?.value as ExchangeFilterEnum) ===
        ExchangeFilterEnum.Include
          ? '+'
          : '-'
    }

    if (
      Array.isArray(config.filter?.['customerDetail']?.value) &&
      config.filter?.['customerDetail']?.value.length === 2
    ) {
      const [customerDetailType, customerDetailValue] = config.filter?.[
        'customerDetail'
      ]?.value as [CustomerDetailTypeEnum, string]

      switch (customerDetailType) {
        case CustomerDetailTypeEnum.Phone:
          variables.phone = customerDetailValue
          break

        case CustomerDetailTypeEnum.Email:
          variables.email = customerDetailValue
          break

        case CustomerDetailTypeEnum.CRMCustomerId:
          variables.crmCustomerId = customerDetailValue
          break

        case CustomerDetailTypeEnum.CRMMemberNumber:
        default:
          variables.crmMemberNumber = customerDetailValue
          break
      }
    }

    if (
      config.filter?.['type']?.value &&
      typeof config.filter?.['type']?.value === 'string'
    ) {
      variables.orderType = config.filter?.['type']
        ?.value as OrderLookupFilterOrderTypeEnum
    }

    if (
      config.filter?.['returnMethod']?.value &&
      Array.isArray(config.filter?.['returnMethod']?.value) &&
      config.filter?.['returnMethod']?.value.length > 0
    ) {
      variables.refundInitiationMode = config.filter?.['returnMethod']?.value
    }

    if (
      config.filter?.['skus']?.value &&
      Array.isArray(config.filter?.['skus']?.value) &&
      config.filter?.['skus']?.value.length > 0
    ) {
      variables.itemSku = config.filter?.['skus']?.value
    }

    if (
      config.filter?.['productGroup']?.value &&
      Array.isArray(config.filter?.['productGroup']?.value) &&
      config.filter?.['productGroup']?.value.length > 0
    ) {
      variables.itemProductGroupName = config.filter?.['productGroup']?.value
    }

    if (
      config.filter?.['multipleStaff']?.value &&
      typeof config.filter?.['multipleStaff']?.value === 'boolean'
    ) {
      variables.staffUserIdHelpers = ['+']
    }

    if (
      config.filter?.['manualOrderItemDiscount']?.value &&
      typeof config.filter?.['manualOrderItemDiscount']?.value === 'boolean'
    ) {
      variables.itemReasonCodeManualDiscount = ['+']
    }

    if (
      config.filter?.['manualOrderItemPrice']?.value &&
      typeof config.filter?.['manualOrderItemPrice']?.value === 'boolean'
    ) {
      variables.itemReasonCodeManualPrice = ['+']
    }

    if (
      config.filter?.['campaign']?.value &&
      Array.isArray(config.filter?.['campaign']?.value) &&
      config.filter?.['campaign']?.value.length > 0
    ) {
      variables.itemVoucherName = config.filter?.['campaign']?.value
    }

    return variables
  }, [filter, apiRef, sortItems, getNowDateString, getPaymentMethods])

  const {
    data,
    loading: ordersLoading,
    fetchMore,
  } = useQuery(SearchOrdersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = ordersLoading || !isFilterReady

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'orderid',
        minWidth: 80,
        headerName: t('orders:id'),
        valueGetter: (_value, row): string => `#${row.orderid}`,
      },
      {
        field: 'externalid',
        minWidth: 120,
        headerName: t('orders:external_id'),
      },
      {
        field: 'custom-receipt-id',
        minWidth: 120,
        headerName: t('orders:receipt_id'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['pos-receiptid'] || ''
        },
      },
      {
        field: 'orderdate',
        ...ColumnProps.date,
        headerName: t('orders:date'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'orderdatetime',
        ...ColumnProps.date,
        headerName: t('orders:date_time'),
        valueGetter: (_value, row) => row.orderdate,
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'moneytotal_gross_all',
        headerName: `${t('orders:total')} (${me?.currentSite.currencycode})`,
        ...ColumnProps.price,
        renderCell: TotalRenderer,
      },
      {
        field: 'custom-store',
        minWidth: 120,
        headerName: t('orders:store'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['store-name'] || ''
        },
      },
      {
        field: 'custom-staff',
        minWidth: 120,
        headerName: t('orders:staff'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['pos-staff-externalid'] || ''
        },
      },
      {
        field: 'custom-register',
        minWidth: 120,
        headerName: t('orders:register'),
        valueGetter: (_value, row): string =>
          row.cashRegister?.registerkey || '',
      },
      {
        field: 'paymentstateid',
        minWidth: 80,
        headerName: t('orders:payment_state_label'),
        renderCell: PaymentStateRenderer,
      },
      {
        field: 'payments',
        minWidth: 240,
        headerName: t('orders:payments'),
        renderCell: PaymentsRenderer,
      },
      {
        field: 'custom-customer',
        minWidth: 120,
        headerName: t('orders:customer'),
        valueGetter: (_value, row): string => {
          return [row.namefirst, row.namelast].filter(Boolean).join(' ')
        },
      },
      {
        field: 'datereserved',
        ...ColumnProps.date,
        headerName: t('orders:reserve_until'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
    ],
    [formatDate, formatRelativeDate, t, me?.currentSite.currencycode],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.searchOrders.next) {
      return fetchMore({
        variables: {
          start: (data?.searchOrders?.start || 0) + pageSize,
          next: data?.searchOrders.next,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <DataGrid
      name="order-search-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.searchOrders.items}
      rowCount={
        data?.searchOrders.items?.length
          ? data.searchOrders.items.length + 1
          : 0
      }
      loading={isLoading}
      getRowId={(row) => row.orderid}
      onRowClick={(row) => props.onDetailOrder(Number(row.id))}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <OrdersIcon />,
        title: t('orders:grid.empty_title'),
        description: t('orders:grid.empty_description'),
        action: (
          <Button data-testid="show-filter-panel" onClick={props.onShowFilter}>
            {t('orders:set_filter')}
          </Button>
        ),
      }}
      bulkAction={<BulkActions />}
      slots={{ pagination: CursorPagination }}
      sx={{
        '.filter-row-container': {
          gridTemplateColumns: 'auto auto auto 1fr auto',
        },
      }}
      onShowFilter={props.onShowFilter}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: 100 }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      showMore={!!data?.searchOrders.next}
      hideTotalCount
      getRowClassName={(params) => {
        const classes = []
        if (params.id === props.currentDetailedOrderId) {
          classes.push('Mui-selected')
        }

        if (params.row.orderstateid !== OrderState.Open) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
