import { Button, Chip, Container, Grid2 as Grid } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { WarehouseBatchTransaction } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { useWarehouseBatch } from '../use-warehouse-batch'
import { StockTakingFilterPanel } from './filter-panel'
import { InventoryProductList } from './list'
import { ExportContextMenu } from './export-context-menu'
import { FinishStocktakingDialog } from './finish-stocktaking-dialog'

type Params = {
  warehouseId: string
  warehouseBatchId: string
}

export const StocktakingPage2 = () => {
  const { t } = useTranslation(['shared', 'stocktaking', 'products'])
  usePageTitle(t('stocktaking:page_title'))

  const params = useParams<Params>()
  const warehouseId = Number(params.warehouseId)
  const warehouseBatchId = Number(params.warehouseBatchId)

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [openFinishDialog, setOpenFinishDialog] = useState(false)
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(true)

  const { writeStocktaking } = useAuthorization().modules

  const { data: warehouseBatchData } = useWarehouseBatch({
    warehouseId,
    warehouseBatchId,
    transactionType: WarehouseBatchTransaction.Stocktaking,
    listRoute: RootRoute.Stocktakings2,
  })

  const storeName = warehouseBatchData?.warehouseBatch?.warehouse?.store?.name
  const warehouseName = warehouseBatchData?.warehouseBatch?.warehouse?.name

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const closeFilterViewPanel = () => {
    setFilterPanelOpen(false)
  }

  const toggleFilterViewPanel = () => {
    setFilterPanelOpen((state) => !state)
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('stocktaking:page_title')}
            rightColumn={
              <>
                {writeStocktaking && (
                  <>
                    <Button
                      id="stocktaking-more-menu-button"
                      aria-controls={
                        isOpen ? 'stocktaking-more-menu' : undefined
                      }
                      data-testid="stocktaking-more-menu-button"
                      aria-haspopup="true"
                      aria-expanded={isOpen ? 'true' : undefined}
                      onClick={handleClick}
                      color="secondary"
                      endIcon={<ChevronSmallDownIcon />}
                    >
                      {t('shared:action.more')}
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => setOpenFinishDialog(true)}
                      data-testid="finish-stocktaking"
                    >
                      {t('shared:action.finish')}
                    </Button>

                    <FinishStocktakingDialog
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                      open={openFinishDialog}
                      onClose={() => setOpenFinishDialog(false)}
                      onSuccess={() => {
                        navigate(generatePath(RootRoute.Stocktakings2))
                        setOpenFinishDialog(false)
                      }}
                    />
                    <ExportContextMenu
                      onClose={closeMenu}
                      anchorEl={anchorEl}
                      isOpen={isOpen}
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                    />
                  </>
                )}
              </>
            }
            showBackButton
            isFlexible
            backText={t('shared:menu.stocktaking')}
            backTo={generatePath(RootRoute.Stocktakings2)}
            subTitle={
              <>
                {storeName && (
                  <Chip label={storeName} color="grayLight" size="small" />
                )}
                {warehouseName && (
                  <Chip label={warehouseName} color="grayLight" size="small" />
                )}
              </>
            }
          />

          <Container maxWidth={false}>
            <InventoryProductList
              onShowFilter={toggleFilterViewPanel}
              warehouseId={warehouseId}
              warehouseBatchId={warehouseBatchId}
            />
          </Container>
        </Grid>

        <Grid>
          <StockTakingFilterPanel
            isOpen={isFilterPanelOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
