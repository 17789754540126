import { DataGrid } from '../../../../components/data-grid'
import { GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  AllOpenWarehouseBatchesDocument,
  AllOpenWarehouseBatchesQuery,
  WarehouseBatchTransaction,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useQuery } from '@apollo/client'

type Row = ArrayElement<AllOpenWarehouseBatchesQuery['allOpenWarehouseBatches']>

export const StocktakingList = () => {
  const { t } = useTranslation(['stocktaking'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const apiRef = useGridApiRef()

  const { data, loading } = useQuery(AllOpenWarehouseBatchesDocument, {
    variables: {
      transactiontype: WarehouseBatchTransaction.Stocktaking,
    },
    fetchPolicy: 'cache-and-network',
  })

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'warehousebatchid',
      minWidth: 80,
      headerName: t('stocktaking:id'),
    },
    {
      field: 'datecreated',
      ...ColumnProps.date,
      headerName: t('stocktaking:date_created'),
      renderCell: (params) => (
        <RelativeDateRenderer
          {...params}
          formatDate={formatDate}
          formatRelativeDate={formatRelativeDate}
        />
      ),
    },
    {
      field: 'custom-user',
      minWidth: 240,
      headerName: t('stocktaking:user'),
      valueGetter: (_value, row): string => {
        if (row.user && (row.user.namefirst || row.user.namelast)) {
          return [row.user.namefirst, row.user.namelast]
            .filter(Boolean)
            .join(' ')
        }

        return row.emailowner
      },
    },
    {
      field: 'custom-store',
      minWidth: 240,
      headerName: t('stocktaking:store'),
      valueGetter: (_value, row): string => {
        return row.warehouse?.store?.name || ''
      },
    },
    {
      field: 'custom-warehouse',
      minWidth: 240,
      headerName: t('stocktaking:warehouse'),
      valueGetter: (_value, row): string => {
        return row.warehouse?.name || ''
      },
    },
  ]

  const warehouseBatches = useMemo(() => {
    return data?.allOpenWarehouseBatches?.map((x) => ({
      ...x,
      id: x.warehousebatchid,
    }))
  }, [data?.allOpenWarehouseBatches])

  return (
    <DataGrid
      name="stocktaking"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={warehouseBatches}
      rowCount={warehouseBatches?.length || 0}
      loading={loading}
      noRowsOverlay={{
        icon: <WarehouseIcon />,
        title: t('stocktaking:stocktaking.empty_title'),
        description: t('stocktaking:stocktaking.empty_description'),
      }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: 100 }}
      updateSearchParams
      columnVisibilityModel={{ warehousebatchid: false }}
      onRowClick={(params: GridRowParams<Row>) =>
        navigate(
          generatePath(RootRoute.Stocktaking, {
            warehouseId: String(params.row.warehouseid),
            warehouseBatchId: String(params.row.warehousebatchid),
          }),
        )
      }
      showMore
    />
  )
}
