import { useQuery } from '@apollo/client'
import {
  GetWarehouseBatchDocument,
  WarehouseBatchTransaction,
  WarehouseBatchState,
} from '../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

type Props = {
  warehouseId: number
  warehouseBatchId: number
  transactionType: WarehouseBatchTransaction
  listRoute: string
}

export const useWarehouseBatch = (props: Props) => {
  const { t } = useTranslation(['shared'])
  const { warehouseId, warehouseBatchId, transactionType, listRoute } = props

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading } = useQuery(GetWarehouseBatchDocument, {
    variables: { warehouseBatchId, warehouseId },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data) return

    const isMatchingTransaction =
      data.warehouseBatch?.transactiontype === transactionType

    const isOpen =
      data.warehouseBatch?.warehousebatchstate === WarehouseBatchState.Open

    if (!data.warehouseBatch || !isMatchingTransaction || !isOpen) {
      enqueueSnackbar(t('shared:error.resource_not_found'), {
        variant: 'error',
      })

      return navigate(generatePath(listRoute))
    }
  }, [
    data,
    enqueueSnackbar,
    generatePath,
    listRoute,
    navigate,
    transactionType,
    t,
  ])

  return { data, loading }
}
