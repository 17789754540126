import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { FolderIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  GetProductsQuery,
  GetProductsQueryVariables,
  GetProductsSort,
  GetCategoriesDocument,
  GetProductsDocument,
} from '../../../generated/graphql'
import { FormatCurrencyOptions, useMoney } from '../../../hooks/money'
import {
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
  MAX_NUM_REQUEST,
} from '../../../utils/constants'
import { CaptionRenderer } from '../../../components/data-grid/utils/caption-renderer'
import { formatCategoryName } from '../../../utils/format/category'
import { ArrayElement } from '../../../utils/types'
import { BulkActions } from './bulk-actions'
import { ProductStateRenderer } from './product-state'
import { useMe } from '../../../hooks/me'
import { useAuthorization } from '../../../hooks/authorization'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useQuery } from '@apollo/client'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { ManufacturerIdFilter } from '../../../filters/manufacturer-id-filter'
import { CampaignIdFilter } from '../../../filters/campaign-id-filter'
import { ProductGroupIdFilter } from '../../../filters/product-group-id-filter'
import { CategoryIdFilter } from '../../../filters/product-category-id-filter'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import { formatProductTitle } from '../../../components/data-grid/utils/format-product-title'

type ProductListProps = {
  onDetailProduct: (productId: number, siteId?: number) => void
  onCloseDetailProduct: () => void
  currentDetailedProductId?: number
  onShowFilter(): void
}

type Row = ArrayElement<GetProductsQuery['products']['items']>

const PAGE_SIZE = 100

// TODO: move types to the corresponding filters
type StateFilter = { state?: string }
type SiteIdFilter = { siteId?: number }

type Filter = ManufacturerIdFilter &
  CampaignIdFilter &
  StateFilter &
  TextFilter &
  SiteIdFilter &
  ProductGroupIdFilter &
  CategoryIdFilter

export const ProductList = (props: ProductListProps) => {
  const { t } = useTranslation(['products', 'shared'])

  const apiRef = useGridApiRef()

  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('products:id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('products:id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('products:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('products:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('products:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('products:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const { filter, isFilterReady } =
    useContext<FilterContext<Filter>>(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetProductsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      includeVariants: false,
      fallbackPriceToCommon: true,
    }

    variables.search = config.filter.text?.value
    variables.categoryIds = config.filter.categoryIds?.value
    variables.productGroupIds = config.filter.productGroupIds?.value
    variables.campaignIds = config.filter.campaignIds?.value
    variables.manufacturerIds = config.filter.manufacturerIds?.value

    if (config.filter.state?.value) {
      variables.includeInactive = config.filter.state.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [apiRef, filter, sortItems])

  const siteId = useMemo(() => {
    return queryVariables.productSiteId || undefined
  }, [queryVariables.productSiteId])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchProducts,
  } = useQuery(GetProductsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const {
    modules: { writeProducts },
  } = useAuthorization()
  const {
    data: allCategories,
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST, categorySiteId: siteId },
  })

  const { formatCurrency, formatCurrencyRange } = useMoney()
  const { me, loading: meLoading } = useMe()

  useEffect(() => {
    void refetchCategories({
      num: MAX_NUM_REQUEST,
      categorySiteId: siteId,
    })
  }, [refetchCategories, siteId])

  const isLoading =
    fetchLoading || categoriesLoading || meLoading || !isFilterReady

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'productImages',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row) => row.productImages?.[0]?.fileUrl,
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'id',
        ...ColumnProps.productId,
        headerName: t('products:id'),
      },
      {
        field: 'title',
        ...ColumnProps.productTitle,
        headerName: t('products:product'),
        valueGetter: (_value, row) => formatProductTitle(_value, row),
        renderCell: CaptionRenderer,
      },
      {
        field: 'activepos',
        ...ColumnProps.productActive,
        headerName: t('products:status'),
        renderCell: ProductStateRenderer,
      },
      {
        field: 'sku',
        ...ColumnProps.sku,
        headerName: t('products:sku'),
      },
      {
        field: 'barcode',
        ...ColumnProps.barcode,
        headerName: t('products:barcode'),
      },
      {
        field: 'pricelistitems',
        ...ColumnProps.productPriceRange,
        headerName: `${t('products:price')} (${me?.currentSite.currencycode})`,
        valueFormatter: (_value, row) => {
          const value =
            row.pricelistitems?.filter(
              (x) => x.pricelist?.currencycode === me?.currentSite.currencycode,
            ) || []
          const formatCurrencyOptions: FormatCurrencyOptions = {
            hideCurrency: true,
            forceDecimals: true,
          }

          if (value.length === 0)
            return formatCurrency('', formatCurrencyOptions)

          if (value.length === 1)
            return formatCurrency(
              value[0]?.moneyprice || '',
              formatCurrencyOptions,
            )

          const orderedValues =
            value.map((x) => Number(x.moneyprice)).sort((a, b) => a - b) || []
          const minimumValue = orderedValues.shift() || 0
          const maximumValue = orderedValues.pop() || 0

          return formatCurrencyRange(
            minimumValue,
            maximumValue,
            formatCurrencyOptions,
          )
        },
      },
      {
        field: 'productgroup',
        ...ColumnProps.productGroup,
        headerName: t('products:product_group'),
        valueGetter: (value: Row['productgroup']) => {
          if (!value) return null

          return `${value.name} (${value.value || 0}%)`
        },
      },
      {
        field: 'datemodified',
        ...ColumnProps.date,
        headerName: t('products:modified'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'allcategories',
        ...ColumnProps.productCategory,
        headerName: t('products:main_category'),
        valueGetter: (_value, row) =>
          formatCategoryName(
            row?.defaultcategoryid,
            allCategories?.categories.items,
            true,
          ),
      },
    ],
    [
      allCategories?.categories.items,
      formatCurrency,
      formatCurrencyRange,
      formatDate,
      formatRelativeDate,
      me?.currentSite.currencycode,
      t,
    ],
  )

  return (
    <DataGrid
      apiRef={apiRef}
      name="product-list"
      columns={dataGridColumns}
      rows={data?.products.items}
      rowCount={data?.products.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      onRowClick={(p) => {
        props.onDetailProduct(p.id as number, siteId)
      }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('products:grid.title'),
        description: t('products:grid.description'),
      }}
      rowHeight={50}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      slots={{ pagination: CursorPagination }}
      onShowFilter={props.onShowFilter}
      hasTextFilter
      textFilterPlaceHolder={t('products:search_placeholder')}
      bulkAction={
        <>
          {writeProducts && (siteId === undefined || me?.siteId === siteId) && (
            <BulkActions
              refetch={refetchProducts}
              onCloseDetailProduct={props.onCloseDetailProduct}
              currentDetailedProductId={props.currentDetailedProductId}
            />
          )}
        </>
      }
      disableColumnFilter
      checkboxSelection
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      getRowClassName={(params) =>
        params.id === props.currentDetailedProductId ? 'Mui-selected' : ''
      }
      sorting={sortItems}
      columnVisibilityModel={{
        id: false,
        barcode: false,
        activepos: false,
      }}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
