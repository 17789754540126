import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AllReasonCodesDocument,
  ReasonCodeType,
  UpdateWarehouseBatchDocument,
  WarehouseBatchState,
} from '../../../../generated/graphql'
import { useMe } from '../../../../hooks/me'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessages } from '../../../../utils/error-mapping'
import { useBatchStatus } from './batch-status'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { SelectInput } from '../../../../inputs/select-input'

type Props = {
  open: boolean
  warehouseId: number
  warehouseBatchId: number
  onClose: () => void
  onSuccess: () => void
  onError?(): void
}

type Form = {
  reasonCode: string
  comment: string
  confirm: boolean
}

export const FinishStocktakingDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'stocktaking'])
  const {
    trackDialogOpen,
    trackDialogClose,
    trackButtonClickEvent,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const dialogName = 'finish-stocktaking'
  const [updateWarehouseBatch, { loading: updatingWarehouseBatch }] =
    useMutation(UpdateWarehouseBatchDocument)
  const { enqueueSnackbar } = useSnackbar()
  const { displayName } = useMe()

  const formContext = useForm<Form>({
    defaultValues: {
      reasonCode: '',
      comment: '',
      confirm: false,
    },
  })

  const {
    deviations,
    loading: loadingWarehouseBatchStatus,
    values,
    refetch: refetchWarehouseBatchStatus,
  } = useBatchStatus(props.warehouseBatchId, props.warehouseId)

  const { data: reasonCodesData, loading: loadingReasonCodes } = useQuery(
    AllReasonCodesDocument,
    {
      variables: {
        type: ReasonCodeType.StockTaking,
      },
      skip: deviations.length === 0,
    },
  )
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const isLoading =
    updatingWarehouseBatch || loadingReasonCodes || loadingWarehouseBatchStatus

  const submit = async (data: Form) => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-finish` })

    try {
      await updateWarehouseBatch({
        variables: {
          warehouseBatchId: props.warehouseBatchId,
          warehouseId: props.warehouseId,
          data: {
            warehousebatchstate: WarehouseBatchState.Closed,
            comment: data.comment,
            reasoncode: data.reasonCode,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      enqueueSnackbar(t('stocktaking:finish_dialog.success'))
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessages(error, {
        fallbackError: t('stocktaking:finish_dialog.error'),
      }).at(0)

      trackFormError({
        name: `${dialogName}-dialog`,
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
      props.onError?.()
    }
  }

  useEffect(() => {
    if (props.open) {
      void refetchWarehouseBatchStatus()
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, trackDialogOpen, formContext, refetchWarehouseBatchStatus])

  const onClose = () => {
    formContext.reset()

    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  const getAsterisks = (amount: number) =>
    Array.from({ length: amount }, () => '*').join('')

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{t('stocktaking:finish_dialog.title')}</DialogTitle>

      <DialogContent>
        <SectionHeader variant="transparent" sx={{ px: 0 }}>
          {t('stocktaking:finish_dialog.summary')}
        </SectionHeader>

        <List
          sx={{
            mx: -3,
          }}
        >
          {values.map(({ value, label }) => (
            <ListItem
              key={label}
              secondaryAction={<ListItemText>{value}</ListItemText>}
              divider
            >
              <ListItemText>{label}</ListItemText>
            </ListItem>
          ))}
          {deviations.map(({ value, label }, index) => (
            <ListItem
              key={label}
              divider
              secondaryAction={
                <ListItemText>
                  {value}
                  {getAsterisks(index + 1)}
                </ListItemText>
              }
              sx={{
                color: (theme) => theme.palette.redBase,
              }}
            >
              <ListItemText>{label}</ListItemText>
            </ListItem>
          ))}
        </List>
        {deviations.map(({ annotation }, index) => (
          <Typography
            key={index}
            variant="caption"
            sx={{ mb: 1, whiteSpace: 'pre-wrap' }}
            component="div"
          >
            {getAsterisks(index + 1)}
            {annotation}
          </Typography>
        ))}
        {deviations.length === 0 && (
          <Typography
            variant="caption"
            sx={{ mb: 3, whiteSpace: 'pre-wrap' }}
            component="div"
          >
            {t('stocktaking:finish_dialog.annotation_validate_modified')}
          </Typography>
        )}
        {reasonCodes.length > 0 && (
          <SelectInput
            options={reasonCodes.map(({ name, reasoncode, reasoncodeid }) => ({
              name: name || reasoncode,
              value: reasoncode,
              key: reasoncodeid,
            }))}
            name="reasonCode"
            label={t('stocktaking:finish_dialog.reason_code')}
            control={formContext.control}
            rules={{
              required: true,
            }}
          />
        )}

        <TextFieldInput
          fullWidth
          error={!!formContext.formState.errors.comment}
          helperText={formContext.formState.errors.comment?.message}
          label={t('stocktaking:finish_dialog.comment')}
          name="comment"
          control={formContext.control}
          slotProps={{
            htmlInput: { 'data-testid': 'comment' },
          }}
          multiline={true}
          rows={3}
        />
        <List
          sx={{
            mx: -3,
          }}
        >
          <ListItem
            secondaryAction={<ListItemText>{displayName}</ListItemText>}
          >
            <ListItemText>
              {t('stocktaking:finish_dialog.responsible')}
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>

      <DialogActions>
        <Box sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}>
          <Controller
            control={formContext.control}
            name="confirm"
            rules={{
              required: t('stocktaking:finish_dialog.checkbox_error_message'),
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        typeof field.value === 'boolean' ? field.value : false
                      }
                      onChange={(event) => {
                        field.onChange(event.target.checked)
                      }}
                      data-testid="confirm-stocktaking"
                    />
                  }
                  label={t('stocktaking:finish_dialog.confirmation_message')}
                />
                {error && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </>
            )}
          />
        </Box>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-finish-stocktaking"
        >
          {t('shared:action.cancel')}
        </Button>
        <Button
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={formContext.handleSubmit(submit)}
          data-testid="dialog-finish-finish-stocktaking"
          disabled={isLoading}
        >
          {t('shared:action.finish')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
