import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'
import { Indicator } from '../../indicator'

type Params = GridRenderCellParams<GridValidRowModel, number | null | undefined>

export const StockRenderer = (params: Params) => {
  const { id, field, value } = params

  return (
    <div
      className="MuiDataGrid-cellContent"
      data-testid={`string-field-${id}-${field}`}
    >
      <Indicator active={Number(value) > 0}>{value}</Indicator>
    </div>
  )
}
