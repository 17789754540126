import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { FranchiseSiteFilter } from '../../../filters/franchise-site-filter'
import { ManufacturerIdFilter } from '../../../filters/manufacturer-id-filter'
import { ProductCategoryIdFilter } from '../../../filters/product-category-id-filter'
import { ProductGroupFilter } from '../../../filters/product-group-id-filter'
import { WarehouseProductStateFilter } from '../../../filters/warehouse-product-state-filter'
import { WarehouseItemStateFilter } from '../../../filters/warehouse-item-state-filter'

type Props = {
  isOpen: boolean
  onClose(): void
}

export const StockTakingFilterPanel = (props: Props) => {
  const { isOpen, onClose } = props
  const { t } = useTranslation(['stocktaking'])

  return (
    <FilterViewPanel open={isOpen} onClose={onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('stocktaking:page_title')}
          </SectionHeader>
        </AccordionSummary>

        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <WarehouseItemStateFilter type="stocktaking" />
            </ListItem>

            <ListItem>
              <WarehouseProductStateFilter />
            </ListItem>

            <ListItem>
              <ProductCategoryIdFilter />
            </ListItem>

            <ListItem>
              <ProductGroupFilter />
            </ListItem>

            <ListItem>
              <ManufacturerIdFilter />
            </ListItem>

            <ListItem sx={{ '&:empty': { display: 'none' } }}>
              <FranchiseSiteFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
