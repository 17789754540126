import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'

export type CaptionRendererValue = {
  title: React.ReactNode
  caption: React.ReactNode
  icon?: React.ReactNode
}

export const CaptionRenderer = (
  params: GridRenderCellParams<GridValidRowModel, CaptionRendererValue>,
) => {
  const title = params.formattedValue?.title
  const caption = params.formattedValue?.caption

  return (
    <Stack direction="row" alignItems="center" height="100%" gap={0.5}>
      {params.formattedValue?.icon}

      <Stack
        data-testid={`caption-${params.id}-${params.field}`}
        direction="column"
        sx={{
          justifyContent: 'center',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {title && <Typography>{title}</Typography>}

        {caption && (
          <Typography
            variant="caption"
            sx={{
              mt: -0.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text.secondary',
            }}
          >
            {caption}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
