import { useQuery, useReactiveVar } from '@apollo/client'
import { Button, Paper } from '@mui/material'
import { ServiceOrdersIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { serviceOrderViewPanelVar } from '..'
import { RootRoute } from '../..'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { ServiceOrderDocument } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { DEFAULT_SERVICE_ORDER_ID } from '../../../utils/constants'
import { ServiceOrderStateChip } from '../service-order-state-chip'
import { Customer } from './customer'
import { Information } from './information'
import { Product } from './product'
import { Services } from './services'

type Props = {
  onClose: () => void
}

export const ServiceOrderViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'service_orders'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { isOpen, serviceOrderId, previous } = useReactiveVar(
    serviceOrderViewPanelVar,
  )
  const { trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()
  const { data, loading } = useQuery(ServiceOrderDocument, {
    variables: { serviceOrderId },
    skip: serviceOrderId === DEFAULT_SERVICE_ORDER_ID,
  })

  const serviceOrder = data?.serviceOrder

  return (
    <>
      <ViewPanel open={isOpen} ref={panelRef}>
        <ViewPanelHeader
          isLoading={loading}
          title={`#${serviceOrder?.id || ''}`}
          section={t('service_orders:page_title')}
          onClose={props.onClose}
          hideActionsSkeleton
          data-testid="service-order-view-panel-header"
          onBack={() => {
            trackButtonClick({ name: 'service-order-panel-go-back' })
            serviceOrderViewPanelVar(previous)
          }}
          showBackButton={!!previous}
          sectionIcon={<ServiceOrdersIcon />}
          tags={
            <ServiceOrderStateChip
              label={t(`service_orders:states.${serviceOrder?.state}`)}
              state={serviceOrder?.state}
            />
          }
          actions={
            <Button
              component={Link}
              to={generatePath(RootRoute.ServiceOrder, {
                id: serviceOrderId,
              })}
              color="secondary"
              size="small"
              data-testid="edit-service-order-button"
              onClick={() => {
                trackButtonClick({
                  name: 'service-order-panel-edit',
                  serviceOrderId: serviceOrderId,
                })
              }}
            >
              {t('shared:action.edit')}
            </Button>
          }
        />

        <Paper elevation={0}>
          <Product serviceOrder={data?.serviceOrder} isLoading={loading} />
          <Services
            services={data?.serviceOrder.product.services}
            isLoading={loading}
            totalAfterShare={data?.serviceOrder.product.totalAfterShare}
            totalBeforeShare={data?.serviceOrder.product.totalBeforeShare}
            currency={data?.serviceOrder.currency}
          />
          <Information serviceOrder={data?.serviceOrder} isLoading={loading} />
          <Customer
            customer={data?.serviceOrder.customer}
            isLoading={loading}
          />
        </Paper>
      </ViewPanel>
    </>
  )
}
