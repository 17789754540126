import { useFormContext } from 'react-hook-form'
import { CampaignFormContext } from '../..'
import { PriceListTagsInput } from '../../../../inputs/price-list-tags-input'

type Props = {
  label?: string
}

export const PricelistTagsField = (props: Props) => {
  const { control } = useFormContext<CampaignFormContext>()

  return (
    <PriceListTagsInput
      name="campaign.pricelisttags"
      control={control}
      label={props.label}
      required
      sx={{ px: 2, mb: 2 }}
    />
  )
}
