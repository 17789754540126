import { StrictMode } from 'react'
import { RouterProvider } from './providers/router'
import { ThemeProvider } from './providers/theme'
import { SnackbarProvider } from './providers/snackbar'
import { ApolloProvider } from './providers/apollo'
import { AnalyticsProvider } from './providers/analytics'
import { EmotionCacheProvider } from './providers/emotion-cache'
import { LocalizationProvider } from './providers/localization'
import { NotificationProvider } from './providers/notification'

export const App = () => {
  return (
    <StrictMode>
      <AnalyticsProvider>
        <ApolloProvider>
          <EmotionCacheProvider>
            <ThemeProvider>
              <LocalizationProvider>
                <SnackbarProvider>
                  <NotificationProvider />
                  <RouterProvider />
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </EmotionCacheProvider>
        </ApolloProvider>
      </AnalyticsProvider>
    </StrictMode>
  )
}
