import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { AllFranchiseSitesDocument } from '../generated/graphql'
import { useFormFilter } from '../hooks/form-filter'
import { SelectInput } from '../inputs/select-input'
import { useMe } from '../hooks/me'

export type FranchiseSiteFilter = {
  siteId?: number
}

export const FranchiseSiteFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])

  const filterKey = 'siteId'

  const { siteId: defaultSiteId } = useMe()

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<FranchiseSiteFilter>>(FilterContext)

  const { data, loading: isLoading } = useQuery(AllFranchiseSitesDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(
    () =>
      [...(data?.allFranchiseSites || [])].map((site) => ({
        name: site.eshopname,
        value: site.eshopid,
      })),
    [data?.allFranchiseSites],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<FranchiseSiteFilter>({
      formProps: {
        defaultValues: { siteId: defaultSiteId },
      },
      parseOptions: {
        types: { siteId: (value) => Number(value) },
      },
    })

  const { siteId } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (siteId) {
      setFilter(filterKey, {
        label: t('filter:franchise_site_filter.label'),
        labelValues: [String(siteId)],
        value: siteId,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, siteId, removeFilter, setFilter, t, isLoading])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  if (!data?.allFranchiseSites || data.allFranchiseSites.length < 2) {
    return null
  }

  return (
    <SelectInput
      name="siteId"
      control={formContext.control}
      options={options}
      disabled={isLoading}
      label={t('filter:franchise_site_filter.label')}
    />
  )
}
