import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../../hooks/tracking'
import {
  UpdateUsersTagsActions,
  UpdateUsersTagsDialog,
} from '../../update-users-tags-dialog'
import { useMutation } from '@apollo/client'
import { ExportUsersDocument, FileType } from '../../../../generated/graphql'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { Row } from '..'
import { ExportDialog } from '../../../../components/export-dialog'
import { useSnackbar } from 'notistack'

export const BulkActions = () => {
  const { t } = useTranslation(['shared', 'users'])
  const { trackButtonClickEvent } = useTracking()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => setAnchorEl(null)

  const { enqueueSnackbar } = useSnackbar()

  const [exportMutation, { loading }] = useMutation(ExportUsersDocument)

  const apiRef = useGridApiContext()

  const selectedIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<Row>[]
  ).map((c) => c.id) as string[]

  const onExport = async (fileType: FileType) => {
    try {
      const { data } = await exportMutation({
        variables: { fileType, userIds: selectedIds },
      })

      const fileLink = data?.exportUsers

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('users:export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('users:export_error'), {
        variant: 'error',
      })
    } finally {
      setExportDialogOpen(false)
    }
  }

  const [showAddUsersTags, setShowAddUserTags] = useState(false)
  const [showRemoveUsersTags, setShowRemoveUsersTags] = useState(false)
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)

  return (
    <>
      <Button
        id="users-list-bulk-actionsmenu-button"
        aria-controls={isOpen ? 'users-list-bulk-actionsmenu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>
      <Menu
        id="users-list-bulk-actionsmenu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'users-list-bulk-actionsmenu-button',
        }}
      >
        <MenuItem
          onClick={trackButtonClickEvent(
            { name: 'users-list-bulk-actions-menu-add-user-tags' },
            () => {
              setShowAddUserTags(true)
              closeMenu()
            },
          )}
        >
          <Typography variant="body02">
            {t('users:user_tags.add_user_tags')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={trackButtonClickEvent(
            { name: 'users-list-bulk-actions-menu-add-user-tags' },
            () => {
              setShowRemoveUsersTags(true)
              closeMenu()
            },
          )}
        >
          <Typography variant="body02">
            {t('users:user_tags.remove_user_tags')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={trackButtonClickEvent(
            { name: 'users-list-bulk-actions-menu-export' },
            () => {
              setExportDialogOpen(true)
              closeMenu()
            },
          )}
        >
          <Typography variant="body02">
            {t('users:export_selected_users')}
          </Typography>
        </MenuItem>
      </Menu>

      <UpdateUsersTagsDialog
        open={showAddUsersTags}
        onClose={() => setShowAddUserTags(false)}
        action={UpdateUsersTagsActions.Add}
      />

      <UpdateUsersTagsDialog
        open={showRemoveUsersTags}
        onClose={() => setShowRemoveUsersTags(false)}
        action={UpdateUsersTagsActions.Remove}
      />

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('users:export_selected_users')}
        onSuccess={onExport}
        isLoading={loading}
      />
    </>
  )
}
