import { useMutation } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ExportStoresDocument, FileType } from '../../../generated/graphql'
import { ExportDialog } from '../../../components/export-dialog'
import { useTracking } from '../../../hooks/tracking'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { MoreButton } from '../../../components/more-button'

export const StoresContextMenu = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { trackButtonClickEvent } = useTracking()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const [exportStores, { loading }] = useMutation(ExportStoresDocument)

  const onExport = async (fileType: FileType) => {
    try {
      const { data } = await exportStores({ variables: { fileType } })

      const fileLink = data?.exportStores

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('settings:store.export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('settings:store.export_error'), { variant: 'error' })
    } finally {
      setExportDialogOpen(false)
    }
  }

  return (
    <>
      <MoreButton onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-testid="more-options-button"
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="export-stores-option"
          onClick={
            (trackButtonClickEvent({
              name: 'export-stores',
            }),
            () => setExportDialogOpen(true))
          }
        >
          {t('settings:store.export_stores')}
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('settings:store.export_stores')}
        onSuccess={onExport}
        isLoading={loading}
      />
    </>
  )
}
