import { PaymentUnknownIcon } from '@sitoo/mui-components'
import { PaymentType } from '../../../generated/graphql'
import { paymentMethodIconMap } from './utils'
import { Avatar } from '@mui/material'

type PaymentIconFontSize = 'small' | 'medium' | 'large'

type Props = {
  paymentOption: PaymentType
  fontSize?: PaymentIconFontSize
}

export const PaymentMethodIcon = (props: Props) => {
  const { paymentOption, fontSize = 'medium' } = props

  const customIconFormat: Record<PaymentIconFontSize, number> = {
    small: 20,
    medium: 24,
    large: 35,
  }

  if (paymentOption.iconUrl)
    return (
      <Avatar
        variant="square"
        src={paymentOption.iconUrl}
        sx={{
          width: customIconFormat[fontSize],
          height: customIconFormat[fontSize],
        }}
      />
    )
  const PaymentIcon =
    paymentMethodIconMap[paymentOption.paymentmethodid] || PaymentUnknownIcon

  return <PaymentIcon fontSize={fontSize} />
}
