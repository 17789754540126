import { useQuery } from '@apollo/client'
import { MenuItem, Select } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetProductQuery,
  GetCategoriesDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { MAX_NUM_REQUEST } from '../../../../utils/constants'
import { formatCategoryName } from '../../../../utils/format/category'

export const MainCategoryField = () => {
  const { control } = useFormContext<GetProductQuery>()
  const { data, loading } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST },
  })
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const categories = data?.categories.items

  return (
    <FormFieldset
      label={t('products:product_form.default_category_fieldset')}
      isLoading={loading}
    >
      <Controller
        control={control}
        name="product.defaultcategoryid"
        render={({ field }) => (
          <>
            <Select<number | string>
              value={field.value || ''}
              inputProps={{
                name: 'product.defaultcategory',
                placeholder: t(
                  'products:product_form.default_category_placeholder',
                ),
              }}
              displayEmpty
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
              disabled={!writeProducts}
            >
              <MenuItem value="">{t('shared:label.none')}</MenuItem>
              {categories?.map(({ id }) => (
                <MenuItem value={id} key={id}>
                  {formatCategoryName(id, data?.categories.items, true)}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </FormFieldset>
  )
}
