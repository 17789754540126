import { Divider, FormControl, IconButton } from '@mui/material'
import { CloseIcon, SearchIcon } from '@sitoo/mui-components'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../context'
import { useFormFilter } from '../../../hooks/form-filter'
import { TextFieldInput } from '../../../inputs/text-field-input'

export type TextFilterProps = {
  initialValue?: string
  placeholder?: string
  name: string
}

type QueryParamState = {
  text?: string
}

export type TextFilter = { text?: string }

export const TextFilter = (props: TextFilterProps) => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const filterKey = 'text'

  const { queryParams, formContext, resetForm } =
    useFormFilter<QueryParamState>({
      formProps: {
        defaultValues: { text: '' },
      },
      persistState: false,
    })

  const searchText = queryParams[filterKey] || ''

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  useEffect(() => {
    if (searchText) {
      setFilter(filterKey, {
        label: t('filter:text_filter.label'),
        labelValues: [searchText],
        value: searchText,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [searchText, removeFilter, setFilter, t])

  return (
    <FormControl sx={{ flexGrow: 1 }}>
      <TextFieldInput
        data-testid="search-text-field"
        type="text"
        autoComplete="off"
        name={filterKey}
        control={formContext.control}
        placeholder={props.placeholder || t('shared:grid.search_list')}
        slotProps={{
          input: {
            disableUnderline: true,
            startAdornment: <SearchIcon fontSize="medium" />,
            endAdornment: searchText && (
              <IconButton sx={{ marginRight: 0.5 }} onClick={resetForm}>
                <CloseIcon sx={{ margin: '0 !important' }} />
              </IconButton>
            ),
          },
          htmlInput: {
            background: 'paper',
          },
        }}
        sx={{
          fontSize: (theme) => theme.typography.body01.fontSize,
          '.MuiInputBase-root': {
            height: (theme) => theme.spacing(6),
          },
        }}
      />

      <Divider sx={{ borderWidth: '1px' }} />
    </FormControl>
  )
}
