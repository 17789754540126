import {
  Button,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  Chip,
  Container,
  Grid,
} from '@mui/material'
import { MouseEventHandler, useState } from 'react'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import {
  WarehouseBatchTransaction,
  GetWarehouseBatchDocument,
  WarehouseBatchState,
} from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CancelStocktakingDialog } from './cancel-stocktaking-dialog'
import { FinishStocktakingDialog } from './finish-stocktaking-dialog'
import { ImportStocktakingDialog } from './import-stocktaking-dialog'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { AllStocktakingList } from './all-stocktaking-list'
import { useTracking } from '../../../hooks/tracking'
import { useTabs } from '../../../hooks/tab'
import { OpenStocktakingList } from './open-stocktaking-list'
import { FilterProvider } from '../../../components/data-grid/context'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import { useAuthorization } from '../../../hooks/authorization'
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { AllStocktakingFilterViewPanel } from './all-stocktaking-filter-view-panel'

enum TabLabels {
  Counted = 1,
  All,
}

const stocktakingFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const StocktakingPage = () => {
  const { t } = useTranslation(['shared', 'stocktaking', 'products'])
  usePageTitle(t('stocktaking:page_title'))
  const {
    warehouseId: warehouseIdParams,
    warehouseBatchId: warehouseBatchIdParams,
  } = useParams()
  const warehouseId = Number(warehouseIdParams)
  const warehouseBatchId = Number(warehouseBatchIdParams)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { enqueueSnackbar } = useSnackbar()
  const [openFinishDialog, setOpenFinishDialog] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openImportDialog, setOpenImportDialog] = useState(false)
  const { trackButtonClick } = useTracking()
  const filterViewPanelState = useReactiveVar(stocktakingFilterViewPanelVar)
  const [activeTab, setActiveTab] = useTabs(TabLabels, TabLabels.Counted)
  const filterContext = useFilterContext()
  const {
    modules: { writeStocktaking },
  } = useAuthorization()

  const { data: warehouseBatchData } = useQuery(GetWarehouseBatchDocument, {
    variables: {
      warehouseBatchId,
      warehouseId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (
        !data.warehouseBatch ||
        data.warehouseBatch.transactiontype !==
          WarehouseBatchTransaction.Stocktaking
      ) {
        enqueueSnackbar(t('stocktaking:stocktaking_not_found'), {
          variant: 'warning',
        })
        return navigate(generatePath(RootRoute.Stocktakings))
      }

      if (
        data.warehouseBatch.warehousebatchstate !== WarehouseBatchState.Open
      ) {
        enqueueSnackbar(t('stocktaking:stocktaking_not_open'), {
          variant: 'warning',
        })
        return navigate(generatePath(RootRoute.Stocktakings))
      }
    },
  })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'stocktaking-filter-view-panel-close' })
    stocktakingFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'stocktaking-filter-view-panel-open' })
    stocktakingFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider value={filterContext}>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('stocktaking:page_title')}
            rightColumn={
              <>
                {writeStocktaking && (
                  <>
                    <Button
                      id="stocktaking-more-menu-button"
                      aria-controls={
                        isOpen ? 'stocktaking-more-menu' : undefined
                      }
                      data-testid="stocktaking-more-menu-button"
                      aria-haspopup="true"
                      aria-expanded={isOpen ? 'true' : undefined}
                      onClick={handleClick}
                      color="secondary"
                      endIcon={<ChevronSmallDownIcon />}
                    >
                      {t('shared:action.more')}
                    </Button>
                    <Menu
                      id="stocktaking-more-menu"
                      anchorEl={anchorEl}
                      open={isOpen}
                      onClose={closeMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'stocktaking-more-menu-button',
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenImportDialog(true)
                          closeMenu()
                        }}
                        data-testid="stocktaking-more-menu-import-items"
                      >
                        <Typography variant="body02">
                          {t('stocktaking:import_items')}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenCancelDialog(true)
                          closeMenu()
                        }}
                        data-testid="stocktaking-more-menu-cancel"
                      >
                        <Typography variant="body02">
                          {t('shared:action.cancel')}
                        </Typography>
                      </MenuItem>
                    </Menu>
                    <Button
                      variant="contained"
                      onClick={() => setOpenFinishDialog(true)}
                      data-testid="finish-stocktaking"
                    >
                      {t('shared:action.finish')}
                    </Button>
                    <FinishStocktakingDialog
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                      open={openFinishDialog}
                      onClose={() => setOpenFinishDialog(false)}
                      onSuccess={() => {
                        navigate(generatePath(RootRoute.Stocktakings))
                        setOpenFinishDialog(false)
                      }}
                    />
                    <ImportStocktakingDialog
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                      open={openImportDialog}
                      onClose={() => setOpenImportDialog(false)}
                      onSuccess={() => {
                        filterContext.refetch()
                      }}
                    />
                    <CancelStocktakingDialog
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                      open={openCancelDialog}
                      onClose={() => setOpenCancelDialog(false)}
                      onSuccess={() => {
                        navigate(generatePath(RootRoute.Stocktakings))
                        setOpenCancelDialog(false)
                      }}
                    />
                  </>
                )}
              </>
            }
            showBackButton
            isFlexible
            backText={t('shared:menu.stocktaking')}
            backTo={generatePath(RootRoute.Stocktakings)}
            subTitle={
              <>
                {warehouseBatchData?.warehouseBatch?.warehouse?.store?.name && (
                  <Chip
                    label={
                      warehouseBatchData?.warehouseBatch?.warehouse?.store?.name
                    }
                    size="small"
                  />
                )}
                {warehouseBatchData?.warehouseBatch?.warehouse?.name && (
                  <Chip
                    label={warehouseBatchData?.warehouseBatch?.warehouse?.name}
                    size="small"
                  />
                )}
              </>
            }
          >
            <Tabs
              value={activeTab}
              onChange={(_e, value: number) => {
                trackButtonClick({
                  name: 'orders-tab',
                  value,
                })
                setActiveTab(value)
                stocktakingFilterViewPanelVar({ isOpen: false })
              }}
              variant="standard"
              sx={{
                '.MuiButtonBase-root': {
                  borderColor: 'transparent',
                },
              }}
            >
              <Tab
                label={t('shared:label.counted')}
                value={TabLabels.Counted}
                data-testid="tab-counted"
              />
              <Tab
                label={t('shared:label.all')}
                value={TabLabels.All}
                data-testid="tab-all"
              />
            </Tabs>
          </PageHeader>
          <Container maxWidth={false}>
            {activeTab === TabLabels.All ? (
              <AllStocktakingList
                warehouseBatchId={warehouseBatchId}
                warehouseId={warehouseId}
                onShowFilter={toggleFilterViewPanel}
              />
            ) : (
              <OpenStocktakingList
                warehouseBatchId={warehouseBatchId}
                warehouseId={warehouseId}
              />
            )}
          </Container>
        </Grid>
        <Grid item>
          <AllStocktakingFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
