import { JSX } from 'react'
import {
  GridCellParams,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GRID_REORDER_COL_DEF,
  useGridApiRef,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ProductFormContext } from '../'
import { DataGrid } from '../../../components/data-grid'
import { ArrayElement } from '../../../utils/types'
import { useTranslation } from 'react-i18next'
import { FormatCurrencyOptions, useMoney } from '../../../hooks/money'
import { Box, Button, Divider, Tooltip, Chip, Container } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomAttributeRenderer } from './custom-attribute-render'
import { PlusIcon } from '@sitoo/mui-components'
import { VariantImagesRenderer } from './images-renderer'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../components/data-grid/utils/string-renderer'
import { VariantsRenderer } from './variants-renderer'
import { DeleteVariant } from './delete-variant'
import { useTracking } from '../../../hooks/tracking'
import { useAuthorization } from '../../../hooks/authorization'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { TooltipErrorRenderer } from './tooltip-error-renderer'
import { useMe } from '../../../hooks/me'

type VariantRows = ProductFormContext['product']['childVariants']

export type Row = Exclude<ArrayElement<VariantRows>, null | undefined>

type VariantKey = Exclude<
  keyof Row,
  '__typename' | 'id' | 'isMainVariant' | 'isSingleProduct'
>

export type VariantError = {
  rowId: number
  field: keyof Row
  message: string
}

type Props = {
  isLoading?: boolean
}

export const Variants = (props: Props) => {
  const { t } = useTranslation(['products', 'shared', 'prices'])
  const { control, setValue, watch, formState, trigger } =
    useFormContext<ProductFormContext>()
  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()
  const {
    modules: { writeProducts },
  } = useAuthorization()
  const apiRef = useGridApiRef()
  const {
    formatCurrency,
    formatEditableCurrency,
    formatStringCurrencyToNumber,
  } = useMoney()
  const { me } = useMe()
  const supportedCustomAttributes = watch('supportedCustomAttributes') || []
  const [errors, setErrors] = useState<VariantError[]>([])
  const variantGroups = watch('product.variantGroups')
  const isNewProduct = !watch('product.id')

  const { fields, update } = useFieldArray({
    control,
    name: 'product.childVariants',
    keyName: 'key',
    rules: {
      validate: (v) => {
        const newErrors: VariantError[] = []

        const emptyVariants = v.filter(
          (x) =>
            !x.variant ||
            x.variant.length !== variantGroups?.length ||
            x.variant.some((x) => x.value === ''),
        )
        if (emptyVariants.length > 0) {
          newErrors.push(
            ...emptyVariants.map(
              (x): VariantError => ({
                message: t('products:error.required_property', {
                  property: t('products:variant'),
                }),
                field: 'variant',
                rowId: x.id,
              }),
            ),
          )
        }

        const duplicatedVariants = v.filter(
          (x) =>
            !emptyVariants.some((emptyVariant) => emptyVariant.id === x.id) &&
            v.some(
              (variant) =>
                variant.id !== x.id &&
                variant.variant?.map((v) => v.value).join('') ===
                  x.variant?.map((v) => v.value).join(''),
            ),
        )
        if (duplicatedVariants.length > 0) {
          newErrors.push(
            ...duplicatedVariants.map(
              (x): VariantError => ({
                message: t('products:error.duplicated_property', {
                  property: t('products:variant'),
                }),
                field: 'variant',
                rowId: x.id,
              }),
            ),
          )
        }

        const emptyTitles = v.filter((x) => !x.title)
        if (emptyTitles.length > 0) {
          newErrors.push(
            ...emptyTitles.map(
              (x): VariantError => ({
                message: t('products:error.required_property', {
                  property: t('products:variant_title'),
                }),
                field: 'title',
                rowId: x.id,
              }),
            ),
          )
        }

        const emptySkus = v.filter((x) => !x.sku)
        if (emptySkus.length > 0) {
          newErrors.push(
            ...emptySkus.map(
              (x): VariantError => ({
                message: t('products:error.required_property', {
                  property: t('products:sku'),
                }),
                field: 'sku',
                rowId: x.id,
              }),
            ),
          )
        }

        const duplicatedSkus = v.filter(
          (x) => !!x.sku && v.filter((y) => y.sku === x.sku).length > 1,
        )
        if (duplicatedSkus.length > 0) {
          newErrors.push(
            ...duplicatedSkus.map(
              (x): VariantError => ({
                message: t('products:error.duplicated_property', {
                  property: t('products:sku'),
                }),
                field: 'sku',
                rowId: x.id,
              }),
            ),
          )
        }

        const duplicatedBarcode = v.filter(
          (x) =>
            !!x.barcode && v.filter((y) => y.barcode === x.barcode).length > 1,
        )
        if (duplicatedBarcode.length > 0) {
          newErrors.push(
            ...duplicatedBarcode.map(
              (x): VariantError => ({
                message: t('products:error.duplicated_property', {
                  property: t('products:barcode'),
                }),
                field: 'barcode',
                rowId: x.id,
              }),
            ),
          )
        }

        const invalidPrices = v.filter((x) =>
          Number.isNaN(formatStringCurrencyToNumber(x.moneyprice || '0')),
        )
        if (invalidPrices.length > 0) {
          newErrors.push(
            ...invalidPrices.map(
              (x): VariantError => ({
                message: t('products:error.invalid_property', {
                  property: t('products:price'),
                }),
                field: 'moneyprice',
                rowId: x.id,
              }),
            ),
          )
        }

        const invalidSRPs = v.filter((x) =>
          Number.isNaN(formatStringCurrencyToNumber(x.moneypriceorg || '0')),
        )
        if (invalidSRPs.length > 0) {
          newErrors.push(
            ...invalidSRPs.map(
              (x): VariantError => ({
                message: t('products:error.invalid_property', {
                  property: t('products:price'),
                }),
                field: 'moneypriceorg',
                rowId: x.id,
              }),
            ),
          )
        }

        setErrors(newErrors)

        return newErrors.length === 0
      },
    },
  })

  const setCellValue = useCallback(
    (
      key: VariantKey,
      params: Pick<
        GridPreProcessEditCellProps<unknown, Row>,
        'id' | 'props' | 'row'
      >,
    ) => {
      if (
        params.row.isMainVariant &&
        ['title', 'barcode', 'sku', 'moneyprice', 'moneypriceorg'].includes(
          key,
        ) &&
        typeof params.props.value === 'string'
      ) {
        setValue(`product.${key}`, params.props.value)
      }

      fields.forEach((field, index) => {
        if (field.id === params.id) {
          update(index, { ...field, [key]: params.props.value })
        }
      })

      if (formState.isSubmitted) {
        void trigger('product.childVariants')
      }
    },
    [fields, formState.isSubmitted, setValue, trigger, update],
  )

  const dataGridRows = fields.map((f) => ({ ...f, __reorder__: f.title }))

  const renderWithError = useCallback(
    <T extends GridValidRowModel>(
      params: GridRenderCellParams<T>,
      childrenRenderer: (
        params: GridRenderCellParams<T>,
        triggerChange?: () => void,
      ) => JSX.Element,
    ) => {
      const fieldErrors = errors.filter(
        (x) => x.field === params.field && x.rowId === params.id,
      )

      return (
        <Tooltip title={fieldErrors.map((x) => x.message).join('\n')}>
          {childrenRenderer(params, () => {
            if (formState.isSubmitted) {
              void trigger('product.childVariants')
            }
          })}
        </Tooltip>
      )
    },
    [errors, formState.isSubmitted, trigger],
  )

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const formatCurrencyOptions: FormatCurrencyOptions = {
      hideCurrency: true,
      forceDecimals: true,
    }
    const columns: GridColDef<Row>[] = [
      {
        field: 'variant',
        minWidth: 120,
        headerName: t('products:variant'),
        editable: writeProducts,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <TooltipErrorRenderer rendererProps={params} errors={errors}>
            <VariantsRenderer
              {...params}
              triggerChange={() => {
                if (formState.isSubmitted) {
                  void trigger('product.childVariants')
                }
              }}
            />
          </TooltipErrorRenderer>
        ),
      },
      {
        field: 'title',
        ...ColumnProps.productTitle,
        headerName: t('products:variant_title'),
        editable: writeProducts,
        renderCell: (params: GridRenderCellParams<Row>) =>
          renderWithError(params, () => StringRenderer(params)),
        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'variant-title',
          ),
        preProcessEditCellProps: (params) => {
          setCellValue('title', params)
          return params.props
        },
      },
      {
        field: 'productImages',
        minWidth: 40,
        headerName: t('products:images'),
        editable: writeProducts,
        renderCell: (params) => <VariantImagesRenderer {...params} />,
        renderEditCell: (params) => <VariantImagesRenderer {...params} />,
      },
      {
        field: 'sku',
        ...ColumnProps.sku,
        headerName: t('products:sku'),
        editable: writeProducts,
        renderCell: (params: GridRenderCellParams<Row>) =>
          renderWithError(params, () => StringRenderer(params)),
        renderEditCell: (props) =>
          StringEditRenderer(props, trackInputFocus, trackInputBlur, 'variant'),
        preProcessEditCellProps: (params) => {
          setCellValue('sku', params)
          return params.props
        },
      },
      {
        field: 'barcode',
        ...ColumnProps.barcode,
        headerName: t('products:barcode'),
        editable: writeProducts,
        renderCell: (params: GridRenderCellParams<Row>) =>
          renderWithError(params, () => StringRenderer(params)),
        renderEditCell: (props) =>
          StringEditRenderer(props, trackInputFocus, trackInputBlur, 'variant'),
        preProcessEditCellProps: (params) => {
          setCellValue('barcode', params)
          return params.props
        },
      },
      {
        field: 'moneyprice',
        ...ColumnProps.price,
        headerName: `${t('products:price')} (${me?.currentSite.currencycode})`,
        editable: writeProducts && isNewProduct,
        valueFormatter: (value: string): string => {
          return Number.isNaN(formatStringCurrencyToNumber(value || ''))
            ? '-'
            : formatCurrency(
                formatStringCurrencyToNumber(value || ''),
                formatCurrencyOptions,
              )
        },
        preProcessEditCellProps: (params) => {
          setCellValue('moneyprice', params)
          return params.props
        },
        renderCell: (params: GridRenderCellParams<Row, string>) =>
          renderWithError({ ...params, value: params.formattedValue }, () =>
            StringRenderer(params),
          ),
        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'moneyprice',
          ),
      },
      {
        field: 'moneypriceorg',
        ...ColumnProps.price,
        headerName: `${t('products:srp')} (${me?.currentSite.currencycode})`,
        editable: writeProducts,
        valueFormatter: (value: Row['moneypriceorg']): string =>
          Number.isNaN(formatStringCurrencyToNumber(value || ''))
            ? '-'
            : formatCurrency(
                formatStringCurrencyToNumber(value || ''),
                formatCurrencyOptions,
              ),
        preProcessEditCellProps: (params) => {
          setCellValue('moneypriceorg', params)
          return params.props
        },
        renderCell: (params: GridRenderCellParams<Row, string>) =>
          renderWithError({ ...params, value: params.formattedValue }, () =>
            StringRenderer(params),
          ),
        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'moneypriceorg',
          ),
      },
    ]

    if (supportedCustomAttributes.length > 0) {
      columns.push({
        field: 'customattributes',
        minWidth: 160,
        headerName: t('products:product_form.attributes_fieldset'),
        renderCell: CustomAttributeRenderer,
        renderEditCell: CustomAttributeRenderer,
        editable: writeProducts,
      })
    }

    columns.push(
      ...[
        {
          field: 'isMainVariant',
          minWidth: 80,
          headerName: '',
          editable: false,
          hideable: false,
          renderCell: (params: GridRenderCellParams<Row>) => {
            if (params.formattedValue) {
              return (
                <Chip
                  label={t('products:product_form.main_label')}
                  size="small"
                  color="black"
                />
              )
            }
            return ''
          },
        },
        {
          ...GRID_REORDER_COL_DEF,
          maxWidth: 40,
          hideable: false,
        },
      ],
    )
    return columns
  }, [
    errors,
    formState.isSubmitted,
    formatCurrency,
    formatStringCurrencyToNumber,
    isNewProduct,
    renderWithError,
    setCellValue,
    supportedCustomAttributes.length,
    t,
    trackInputBlur,
    trackInputFocus,
    trigger,
    writeProducts,
    me?.currentSite.currencycode,
  ])

  const addVariantRow = () => {
    const rows = [...fields]
    const lastId = Math.max(...fields.map(({ id }) => id))
    const variantNames = new Set(
      fields.flatMap((f) => f.variant?.map((v) => v.name)) as string[],
    )
    const variant = Array.from(variantNames).map((name) => ({
      name,
      value: '',
    }))

    const mainVariant = rows.find((x) => x.isMainVariant)

    const newRow: Row = {
      id: lastId + 1,
      sku: '',
      moneyprice: mainVariant?.moneyprice || formatEditableCurrency(0),
      moneypriceorg: mainVariant?.moneypriceorg || formatEditableCurrency(0),
      isMainVariant: false,
      isSingleProduct: false,
      variant,
    }

    rows.push({ ...newRow, key: String(newRow.id) })

    setValue('product.childVariants', rows)
  }

  const onRowOrderChange: GridEventListener<'columnOrderChange'> = (params) => {
    const rows = [...fields]
    const initialIndex = params.oldIndex
    const newIndex = params.targetIndex

    const row = rows.splice(initialIndex, 1)[0]

    if (row) {
      rows.splice(newIndex, 0, row)
      setValue('product.childVariants', rows)
    }
  }

  useEffect(() => {
    if (formState.isSubmitted) {
      void trigger('product.childVariants')
    }
  }, [formState.isSubmitted, trigger])

  return (
    <Container maxWidth={false}>
      <DataGrid
        name="product-variants-list"
        apiRef={apiRef}
        columns={dataGridColumns}
        rows={dataGridRows}
        rowCount={fields.length}
        loading={props.isLoading}
        rowHeight={50}
        ignoreRowHover
        bulkAction={
          writeProducts && (
            <div>
              <DeleteVariant />
            </div>
          )
        }
        slots={{
          footer: () => (
            <>
              {writeProducts && (
                <Box sx={{ display: 'grid' }}>
                  <Divider />
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusIcon fontSize="medium" />}
                    onClick={trackButtonClickEvent(
                      { name: 'product-variants-add-new-variant' },
                      addVariantRow,
                    )}
                    data-testid="add-variant-row-button"
                    sx={{ padding: (theme) => theme.spacing(1.75, 0) }}
                  >
                    {t('products:product_form.add_variant_label')}
                  </Button>
                </Box>
              )}
            </>
          ),
        }}
        sx={{
          marginTop: 4,
          '.image-column': {
            padding: '0 !important',
          },
          [`.MuiDataGrid-cell[data-field="productImages"],
            .MuiDataGrid-cell[data-field="variant"]
          `]: {
            position: 'relative',
          },
          '.cell-error.MuiDataGrid-cell--editing, .cell-error.MuiDataGrid-cell--editing:focus-within':
            {
              outline: (theme) => `solid ${theme.palette.redBase} 1px`,
              outlineOffset: '-1px',
            },
          '.cell-error .MuiDataGrid-cellContent': {
            border: (theme) => `1px solid ${theme.palette.redBase}`,
          },
        }}
        getCellClassName={(params: GridCellParams<Row>) => {
          if (errors.length === 0) return ''

          const fieldErrors = errors.filter(
            (x) => x.field === params.field && x.rowId === params.id,
          )
          return fieldErrors.length > 0 ? 'cell-error' : ''
        }}
        checkboxSelection={writeProducts}
        rowReordering={writeProducts}
        onRowOrderChange={onRowOrderChange}
        pagination={false}
      />
    </Container>
  )
}
