import { Box } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  GridRowClassNameParams,
  GridRowsProp,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useContext, useEffect, useMemo, useState } from 'react'
import { CustomOverlay, CustomOverlayProps } from '../custom-overlay'
import { useReactiveVar } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import {
  navbarHeightReactiveVar,
  pageHeaderHeightReactiveVar,
} from '../../cache'
import { PAGE_BOTTOM_OFFSET } from '../../utils/constants'
import { FilterContext } from './context'
import { CustomColumnMenu } from './custom-column-menu'
import { CustomGridHeader } from './custom-grid-header'
import { CustomPagination } from './custom-pagination'
import { Panel } from './custom-panel'
import { ResetFilterButton } from './filters/reset-filter-button'
import { SortItem } from './filters/sort'
import { useDrag } from './hooks/drag'
import { useFocusNextEditable } from './hooks/focus-next-editable'
import { usePersistedGridState } from './hooks/persist-grid-state'
import { useSorting } from './hooks/sorting'
import { COLUMN_HEADER_HEIGHT } from './utils'
import { usePreviousDistinct } from 'react-use'
import { CustomRowCount } from './custom-row-count'

export type DataGridProps<T extends GridValidRowModel> = Omit<
  DataGridProProps<T>,
  'rows'
> & {
  name: string

  fetchMore?(): Promise<unknown> | undefined

  hasTextFilter?: boolean
  textFilterPlaceHolder?: string
  onShowFilter?(): void
  bulkAction?: React.ReactNode
  toolbar?: React.ReactNode
  disableColumnVisibilityControl?: boolean

  sorting?: SortItem[]

  noRowsOverlay?: CustomOverlayProps
  noResultsOverlay?: CustomOverlayProps
  hasPageHeader?: boolean
  disableAllFilters?: boolean

  updateSearchParams?: boolean
  rows: GridRowsProp<T> | null | undefined
  ignoreRowHover?: boolean

  showMore?: boolean
  hideTotalCount?: boolean
}

export const DataGrid = <T extends GridValidRowModel>(
  props: DataGridProps<T>,
) => {
  const {
    name,
    sx,
    bulkAction,
    toolbar,
    sorting,
    noRowsOverlay,
    noResultsOverlay,
    hasPageHeader,
    updateSearchParams,
    rows,
    columns: propsColumns,
    getRowClassName,
    apiRef: propsApiRef,
    ignoreRowHover,
    showMore,
    fetchMore,
    hasTextFilter,
    onShowFilter,
    textFilterPlaceHolder,
    disableAllFilters,
    hideTotalCount,
    ...dataGridProps
  } = props

  const theme = useTheme()

  const internalApiRef = useGridApiRef()
  const apiRef = propsApiRef || internalApiRef
  const { t } = useTranslation(['shared'])

  const pageHeaderHeight = useReactiveVar(pageHeaderHeightReactiveVar)
  const navbarHeight = useReactiveVar(navbarHeightReactiveVar)
  const verticalOffset = PAGE_BOTTOM_OFFSET

  const defaultNoResultsOverlayProps: CustomOverlayProps = {
    title: t('shared:grid.no_results'),
    description: t('shared:grid.no_results_description'),
    action: (
      <ResetFilterButton
        gridName={props.name}
        data-testid="data-grid-reset-filter"
      />
    ),
  }

  const { draggedRowId } = useDrag(apiRef)

  const { filter: dataGridFilter, refetch } = useContext(FilterContext)

  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const focusNextEditable = useFocusNextEditable(apiRef)

  const defaultSortingModel = useMemo(() => {
    const defaultSorting = sorting?.find((x) => x.isDefault === true)
    return defaultSorting ? [defaultSorting] : defaultSorting
  }, [sorting])

  const { sorting: dataGridSorting, onSortChange } = useSorting({
    sorting: defaultSortingModel,
    apiRef,
  })

  const columns = useMemo(() => {
    const orderedFields = apiRef.current?.state?.columns.orderedFields

    return propsColumns
      .map((c) => ({ ...c, sortable: false }))
      .sort((a, b) =>
        orderedFields?.length
          ? orderedFields.indexOf(a.field) - orderedFields.indexOf(b.field)
          : 0,
      )
  }, [apiRef, propsColumns])

  const persistedGridState = usePersistedGridState({
    apiRef,
    columns,
    name: props.name,
    columnVisibilityModel: props.columnVisibilityModel,
  })

  useEffect(() => {
    apiRef.current.subscribeEvent('sortModelChange', refetch)
  }, [apiRef, refetch])

  const [columnVisibility, setColumnVisibility] = useState(
    persistedGridState?.columns?.columnVisibilityModel ||
      props.columnVisibilityModel,
  )

  // We keep the previous rows because we need to display it when it's loading
  // and fetching the data from the apollo query hooks return undefined
  const previousRows = usePreviousDistinct(
    props.rows,
    () => !!props.rows?.length,
  )

  useEffect(() => {
    if (isDataLoaded || !props.rows || !Array.isArray(props.rows)) return

    setIsDataLoaded(true)
  }, [isDataLoaded, props.rows])

  const isLoading = props.loading

  const onGetRowClassName = (params: GridRowClassNameParams<T>): string => {
    const classes: string[] = []

    if (draggedRowId !== null) {
      classes.push(
        params.id === draggedRowId
          ? 'Mui-dragging-row'
          : 'Mui-not-dragging-row',
      )
    }

    if (getRowClassName) classes.push(getRowClassName(params))

    return classes.join(' ')
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'grid',
        height: `calc(100vh - ${pageHeaderHeight + navbarHeight + verticalOffset}px)`,
        ...props.sx,
      }}
    >
      <DataGridPro
        {...dataGridProps}
        initialState={{ sorting: { sortModel: dataGridSorting } }}
        sx={{
          '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
          '.MuiDataGrid-cell--editable:not(.MuiDataGrid-cell--editing):focus-within':
            {
              outline: `1px solid ${theme.palette.blue80}`,
            },
          '.MuiDataGrid-row.Mui-dragging-row': {
            backgroundColor: (theme) => theme.palette.blue20,
          },
          //  While dragging we ignore all the other grid colors (hover gets stuck)
          '.MuiDataGrid-row.Mui-not-dragging-row': {
            backgroundColor: 'transparent',
          },
          '.MuiDataGrid-row:hover': {
            backgroundColor: (theme) =>
              ignoreRowHover ? 'unset' : theme.palette.gray10,
          },
          '.MuiDataGrid-cell--editable:hover': {
            cursor: 'text',
          },
          '.MuiDataGrid-virtualScrollerContent:hover': {
            cursor: props.onRowClick ? 'pointer' : 'default',
          },
          '.MuiDataGrid-cellCaption': {
            display: 'grid',
            height: '100%',
            alignItems: 'center',
            '.MuiTypography-root': {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          '.MuiDataGrid-footerContainer': {
            minHeight: 'auto',
            flexWrap: 'wrap',
          },
          '.disabled': {
            color: (theme) => theme.palette.gray60,
          },
        }}
        nonce={import.meta.env.__CSP_NONCE__}
        rowHeight={props.rowHeight}
        slots={{
          noRowsOverlay: CustomOverlay,
          pagination: CustomPagination,
          toolbar: CustomGridHeader,
          panel: Panel,
          footerRowCount: CustomRowCount,
          columnMenu: CustomColumnMenu,
          ...props.slots,
        }}
        slotProps={{
          toolbar: {
            bulkAction: props.bulkAction,
            toolbar: props.toolbar,
            disableColumnVisibilityControl:
              props.disableColumnVisibilityControl,
          },
          pagination: {
            isLoading,
            hasPageHeader: props.hasPageHeader,
            rowCount: props.rowCount,
            isDataLoaded,
            showMore,
            fetchMore,
            hideTotalCount,
          },

          footerRowCount: {
            hideTotalCount,
          },

          // Below is a workaround since we filter the rows passed to the grid manually instead of using MUI filtermodel
          // Therefore grid will trigger noRowsOverlay for both "no rows" and "no results".
          noRowsOverlay: Object.entries(dataGridFilter).every(
            ([, value]) => value?.isDefault,
          )
            ? props.noRowsOverlay
            : (props.noResultsOverlay ?? defaultNoResultsOverlayProps),

          panel: {
            showFilter: !disableAllFilters,
            hasTextFilter,
            name,
            textFilterPlaceHolder,
            defaultSortingModel,
            onShowFilter,
            sorting,
          },
          ...props.slotProps,
        }}
        disableRowSelectionOnClick
        // disableColumnReorder
        columns={columns}
        // Data
        rows={props.rows || previousRows || []}
        loading={isLoading}
        // Pagination
        paginationMode="server"
        paginationModel={props.paginationModel}
        // Sorting
        sortingMode="server"
        onSortModelChange={onSortChange}
        // Filter : we're not currently using the grid filters
        disableColumnFilter={true}
        // Footer
        hideFooterSelectedRowCount={true}
        // Columns Visibility
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={setColumnVisibility}
        columnHeaderHeight={COLUMN_HEADER_HEIGHT}
        pagination={
          typeof props.showMore !== 'undefined' || dataGridProps.pagination
        }
        apiRef={apiRef}
        getRowClassName={onGetRowClassName}
        onCellEditStop={focusNextEditable}
      />
    </Box>
  )
}
