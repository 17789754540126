import { useReactiveVar } from '@apollo/client'
import {
  Box,
  Container,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { themeState } from '../../../cache'
import { PageHeader } from '../../../components/page-header'
import { useMe } from '../../../hooks/me'
import { usePageTitle } from '../../../hooks/title'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'
import { SupportedLocales } from '../../../i18n'

type GeneralSettingsFormContext = {
  locale: string
  theme: string
}

export const SettingsGeneralPage = () => {
  const { i18n, t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.general'))
  const currentTheme = useReactiveVar(themeState)

  const formContext = useForm<GeneralSettingsFormContext>({
    defaultValues: {
      locale: i18n.language,
      theme: currentTheme,
    },
  })

  const [isEditing, setIsEditing] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const formId = 'generalForm'
  const { me } = useMe()

  const onSubmit = async (data: GeneralSettingsFormContext) => {
    if (data.theme === 'light' || data.theme === 'dark') {
      themeState(data.theme)
    }

    void i18n.changeLanguage(data.locale)

    setIsEditing(false)
    enqueueSnackbar('Saved')

    formContext.reset(data)

    return Promise.resolve()
  }

  return (
    <>
      <RouteLeavingGuard
        when={
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.settings')}
        /*
         // TODO: Enable editing again once we've integrated the settings API

        rightColumn={
          <Button
            type="submit"
            disabled={!formContext.formState.isDirty}
            form={formId}
          >
            {t('shared:action.save')}
          </Button>
        } */
      />
      <Container>
        <Paper elevation={0} sx={{ mb: 2 }}>
          <SectionHeader
            sx={{ px: 2, position: 'relative' }}
            variant="transparent"
          >
            {t('settings:details')}
            {/*
            // TODO: Enable editing again once we've integrated the settings API

            <IconButton
              data-testid="edit-button"
              sx={{
                position: 'absolute',
                top: 3,
                right: (theme) => theme.spacing(1),
              }}
              onClick={() => setIsEditing((p) => !p)}
            >
              {isEditing ? (
                <CloseIcon color="primary" />
              ) : (
                <EditIcon color="primary" />
              )}
            </IconButton> */}
          </SectionHeader>

          {!isEditing && (
            <List>
              <ListItem
                divider
                secondaryAction={
                  <ListItemText>
                    {i18n.language === 'sv'
                      ? t('shared:languages.sv')
                      : t('shared:languages.en')}
                  </ListItemText>
                }
              >
                <ListItemText>{t('shared:label.locale')}</ListItemText>
              </ListItem>
              <ListItem
                divider
                secondaryAction={
                  <ListItemText sx={{ textTransform: 'capitalize' }}>
                    {me?.currentSite?.currencycode}
                  </ListItemText>
                }
              >
                <ListItemText>{t('settings:general.currency')}</ListItemText>
              </ListItem>

              <ListItem
                divider
                secondaryAction={
                  <ListItemText sx={{ textTransform: 'capitalize' }}>
                    {me?.currentSite?.timezone}
                  </ListItemText>
                }
              >
                <ListItemText>{t('settings:general.time_zone')}</ListItemText>
              </ListItem>
            </List>
          )}

          {isEditing && (
            <Box
              component="form"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={formContext.handleSubmit(onSubmit)}
              id={formId}
            >
              <List>
                <ListItem>
                  <ListItemText>
                    <InputLabel>{t('shared:label.locale')}</InputLabel>

                    <Controller
                      control={formContext.control}
                      name="locale"
                      render={({ field }) => (
                        <>
                          <Select
                            value={field.value}
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                          >
                            {SupportedLocales.map((lng) => (
                              <MenuItem value={lng} key={lng}>
                                {t(`shared:languages.${lng}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemText>
                    <InputLabel>{t('settings:general.currency')}</InputLabel>

                    <Select disabled value={me?.currentSite?.currencycode}>
                      <MenuItem value={me?.currentSite?.currencycode}>
                        {me?.currentSite?.currencycode}
                      </MenuItem>
                    </Select>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemText>
                    <InputLabel>{t('settings:general.time_zone')}</InputLabel>

                    <Select disabled value={me?.currentSite?.timezone}>
                      <MenuItem value={me?.currentSite?.timezone}>
                        {me?.currentSite?.timezone}
                      </MenuItem>
                    </Select>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          )}
        </Paper>

        <Paper elevation={0}>
          <SectionHeader sx={{ px: 2 }} variant="transparent">
            {t('settings:general.store_manager_permissions')}
          </SectionHeader>

          <List>
            <ListItem
              secondaryAction={
                <ListItemText>
                  {me?.currentSite?.allow_store_manager_campaigns
                    ? t('shared:label.enabled')
                    : t('shared:label.disabled')}
                </ListItemText>
                /*
                // TODO: Enable editing again once we've integrated the settings API
                <Switch
                  disabled
                  checked={me?.currentSite?.allow_store_manager_campaigns}
                /> */
              }
            >
              <ListItemText>
                {t('settings:general.local_campaigns')}
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Container>
    </>
  )
}
