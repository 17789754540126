import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  WarehouseBatchTransaction,
  AddWarehouseBatchDocument,
  AllOpenWarehouseBatchesDocument,
  WarehouseType,
  GetStoresDocument,
  GetWarehousesDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessage } from '../../../../utils/error-mapping'

type Props = {
  open: boolean
  onClose: () => void
  onSuccess: (warehouseId: number, warehouseBatchId: number) => void
  onError?(): void
}

type Form = {
  storeId: number
  warehouseId: number
}

export const NewStocktakingSessionDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'stocktaking'])
  const {
    trackDialogOpen,
    trackDialogClose,
    trackButtonClickEvent,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = 'new-stocktaking-session'
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: allOpenWarehouseBatchesData,
    loading: loadingWarehouseBatches,
  } = useQuery(AllOpenWarehouseBatchesDocument, {
    variables: {
      transactiontype: WarehouseBatchTransaction.Stocktaking,
    },
    fetchPolicy: 'cache-and-network',
    skip: true, // TODO: resolve this
  })
  const [addWarehouseBatch, { loading }] = useMutation(
    AddWarehouseBatchDocument,
  )

  const formContext = useForm<Form>()

  const { data: storesData, loading: loadingStores } =
    useQuery(GetStoresDocument)

  const { data, loading: loadingWarehouses } = useQuery(GetWarehousesDocument)
  const allWarehouses = data?.allWarehouses

  const fifoWarehouses = useMemo(
    () => allWarehouses?.filter((w) => w.warehousetype === WarehouseType.FIFO),
    [allWarehouses],
  )
  const storeId = formContext.watch('storeId')
  const stores = useMemo(
    () =>
      (storesData?.stores || []).filter((s) => {
        const currentWarehouses =
          fifoWarehouses?.filter((w) => w.storeid === s.id).length || 0

        return (
          currentWarehouses > 0 &&
          currentWarehouses !==
            allOpenWarehouseBatchesData?.allOpenWarehouseBatches.filter(
              (x) => x.warehouse?.store?.id === s.id,
            ).length
        )
      }),
    [
      allOpenWarehouseBatchesData?.allOpenWarehouseBatches,
      fifoWarehouses,
      storesData?.stores,
    ],
  )
  const warehouses = useMemo(
    () =>
      fifoWarehouses?.filter((warehouse) =>
        storeId
          ? warehouse.storeid === Number(storeId) &&
            !allOpenWarehouseBatchesData?.allOpenWarehouseBatches.some(
              (x) => x.warehouseid === warehouse.id,
            )
          : true,
      ) || [],
    [
      allOpenWarehouseBatchesData?.allOpenWarehouseBatches,
      fifoWarehouses,
      storeId,
    ],
  )

  const isLoading =
    loading || loadingStores || loadingWarehouseBatches || loadingWarehouses

  const submit = async (data: Form) => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-create` })

    try {
      const warehouseBatch = await addWarehouseBatch({
        variables: {
          warehouseId: data.warehouseId,
          addWarehouseBatchData: {
            transactiontype: WarehouseBatchTransaction.Stocktaking,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      props.onSuccess?.(
        warehouseBatch.data?.addWarehouseBatch.warehouseid || 0,
        warehouseBatch.data?.addWarehouseBatch.warehousebatchid || 0,
      )
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'stocktaking',
        t('stocktaking:new_session_dialog.error'),
      )

      trackFormError({
        name: `${dialogName}-dialog`,

        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
      props.onError?.()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      formContext.reset({
        storeId: stores[0]?.id || 0,
      })
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, formContext, stores])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <>
      <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>{t('stocktaking:new_session_dialog.title')}</DialogTitle>

        <DialogContent>
          <Controller
            control={formContext.control}
            name="storeId"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('stocktaking:new_session_dialog.store')}
                </InputLabel>
                <Select
                  value={field.value || 0}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="stores-select"
                  inputProps={{ 'data-testid': 'stores-input' }}
                  displayEmpty
                  error={!!error?.message}
                >
                  <MenuItem value={0}>{t('shared:label.all')}</MenuItem>
                  {stores.map(({ id, name }) => (
                    <MenuItem
                      value={id}
                      key={id}
                      data-testid={`store-${id}-button`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
          <Controller
            control={formContext.control}
            name="warehouseId"
            rules={{
              validate: (v) =>
                !v || v === 0
                  ? t('shared:validation.field_required', {
                      field: t('stocktaking:new_session_dialog.warehouse'),
                    })
                  : true,
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('stocktaking:new_session_dialog.warehouse')}
                </InputLabel>
                <Select<number | string>
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="warehouses-select"
                  inputProps={{ 'data-testid': 'warehouses-input' }}
                  displayEmpty
                  error={!!error?.message}
                >
                  <MenuItem value={''} disabled>
                    {t('shared:label.select')}
                  </MenuItem>
                  {warehouses.map(({ id, name }) => (
                    <MenuItem
                      value={id}
                      key={id}
                      data-testid={`warehouse-${id}-button`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            data-testid="dialog-cancel-new-stocktaking"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-create-new-stocktaking"
            disabled={isLoading}
          >
            {t('shared:action.create')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
