import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { GetInventoryBatchesSort } from '../../../generated/graphql'

type Props = {
  shouldShowCustomFields: boolean
}

export const useInventoryBatchSortItems = (props: Props) => {
  const { t } = useTranslation('stocktaking')

  const sortItems = useMemo<SortItem<GetInventoryBatchesSort>[]>(
    () => [
      {
        field: 'sku',
        sort: 'asc',
        title: t('columns.sku'),
        type: 'text',
        value: GetInventoryBatchesSort.Sku,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('columns.sku'),
        type: 'text',
        value: GetInventoryBatchesSort.Sku2,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('columns.title'),
        type: 'text',
        value: GetInventoryBatchesSort.Title,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('columns.title'),
        type: 'text',
        value: GetInventoryBatchesSort.Title2,
      },
      {
        field: 'binlocation',
        sort: 'asc',
        title: t('columns.binlocation'),
        type: 'text',
        value: GetInventoryBatchesSort.Binlocation,
      },
      {
        field: 'binlocation',
        sort: 'desc',
        title: t('columns.binlocation'),
        type: 'text',
        value: GetInventoryBatchesSort.Binlocation2,
      },
      {
        field: 'decimaltotal',
        sort: 'asc',
        title: t('columns.decimaltotal'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimaltotal,
      },
      {
        field: 'decimaltotal',
        sort: 'desc',
        title: t('columns.decimaltotal'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimaltotal2,
      },
      {
        field: 'decimalquantity',
        sort: 'asc',
        title: t('columns.decimalquantity'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimalquantity,
      },
      {
        field: 'decimalquantity',
        sort: 'desc',
        title: t('columns.decimalquantity'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimalquantity2,
      },
      {
        field: 'comment',
        sort: 'asc',
        title: t('columns.comment'),
        type: 'text',
        value: GetInventoryBatchesSort.Comment,
      },
      {
        field: 'comment',
        sort: 'desc',
        title: t('columns.comment'),
        type: 'text',
        value: GetInventoryBatchesSort.Comment2,
      },
      {
        field: 'warehousebatchitemstate',
        sort: 'asc',
        title: t('columns.warehousebatchitemstate'),
        type: 'text',
        value: GetInventoryBatchesSort.Warehousebatchitemstate,
      },
      {
        field: 'warehousebatchitemstate',
        sort: 'desc',
        title: t('columns.warehousebatchitemstate'),
        type: 'text',
        value: GetInventoryBatchesSort.Warehousebatchitemstate2,
      },
      {
        field: 'decimalcounteddiff',
        sort: 'asc',
        title: t('columns.decimalcounteddiff'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimalcounteddiff,
      },
      {
        field: 'decimalcounteddiff',
        sort: 'desc',
        title: t('columns.decimalcounteddiff'),
        type: 'number',
        value: GetInventoryBatchesSort.Decimalcounteddiff2,
      },
      {
        field: 'datelastmodified',
        sort: 'asc',
        title: t('columns.datelastmodified'),
        type: 'date',
        value: GetInventoryBatchesSort.Datelastmodified,
      },
      {
        field: 'datelastmodified',
        sort: 'desc',
        title: t('columns.datelastmodified'),
        type: 'date',
        value: GetInventoryBatchesSort.Datelastmodified2,
      },
      {
        field: 'emailowner',
        sort: 'asc',
        title: t('columns.emailowner'),
        type: 'text',
        value: GetInventoryBatchesSort.Emailowner,
      },
      {
        field: 'emailowner',
        sort: 'desc',
        title: t('columns.emailowner'),
        type: 'text',
        value: GetInventoryBatchesSort.Emailowner2,
      },

      ...(props.shouldShowCustomFields
        ? ([
            {
              field: 'custom1',
              sort: 'asc',
              title: t('columns.custom1'),
              type: 'text',
              value: GetInventoryBatchesSort.Custom1,
            },
            {
              field: 'custom1',
              sort: 'desc',
              title: t('columns.custom1'),
              type: 'text',
              value: GetInventoryBatchesSort.Custom12,
            },
            {
              field: 'custom2',
              sort: 'asc',
              title: t('columns.custom2'),
              type: 'text',
              value: GetInventoryBatchesSort.Custom2,
            },
            {
              field: 'custom2',
              sort: 'desc',
              title: t('columns.custom2'),
              type: 'text',
              value: GetInventoryBatchesSort.Custom22,
            },
          ] as const)
        : []),
      // {
      //   field: 'datelaststocktaking',
      //   sort: 'asc',
      //   title: t('columns.datelaststocktaking'),
      //   type: 'date',
      //   value: GetInventoryBatchesSort.Datelaststocktaking,
      // },
      // {
      //   field: 'datelaststocktaking',
      //   sort: 'desc',
      //   title: t('columns.datelaststocktaking'),
      //   type: 'date',
      //   value: GetInventoryBatchesSort.Datelaststocktaking2,
      // },
      // {
      //   field: 'datemodified',
      //   sort: 'asc',
      //   title: t('columns.datemodified'),
      //   type: 'date',
      //   value: GetInventoryBatchesSort.Datemodified,
      // },
      // {
      //   field: 'datemodified',
      //   sort: 'desc',
      //   title: t('columns.datemodified'),
      //   type: 'date',
      //   value: GetInventoryBatchesSort.Datemodified2,
      // },
      // {
      //   field: 'decimalavailable',
      //   sort: 'asc',
      //   title: t('columns.decimalavailable'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalavailable,
      // },
      // {
      //   field: 'decimalavailable',
      //   sort: 'desc',
      //   title: t('columns.decimalavailable'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalavailable2,
      // },
      // {
      //   field: 'decimalreserved',
      //   sort: 'asc',
      //   title: t('columns.decimalreserved'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalreserved,
      // },
      // {
      //   field: 'decimalreserved',
      //   sort: 'desc',
      //   title: t('columns.decimalreserved'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalreserved2,
      // },
      // {
      //   field: 'decimalthreshold',
      //   sort: 'asc',
      //   title: t('columns.decimalthreshold'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalthreshold,
      // },
      // {
      //   field: 'decimalthreshold',
      //   sort: 'desc',
      //   title: t('columns.decimalthreshold'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalthreshold2,
      // },
      // {
      //   field: 'decimalthresholddiff',
      //   sort: 'asc',
      //   title: t('columns.decimalthresholddiff'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalthresholddiff,
      // },
      // {
      //   field: 'decimalthresholddiff',
      //   sort: 'desc',
      //   title: t('columns.decimalthresholddiff'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Decimalthresholddiff2,
      // },
      // {
      //   field: 'moneytotal',
      //   sort: 'asc',
      //   title: t('columns.moneytotal'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Moneytotal,
      // },
      // {
      //   field: 'moneytotal',
      //   sort: 'desc',
      //   title: t('columns.moneytotal'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Moneytotal2,
      // },
      // {
      //   field: 'productid',
      //   sort: 'asc',
      //   title: t('products:id'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Productid,
      // },
      // {
      //   field: 'productid',
      //   sort: 'desc',
      //   title: t('products:id'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Productid2,
      // },
      // {
      //   field: 'vatid',
      //   sort: 'asc',
      //   title: t('columns.vatid'),
      //   type: 'text',
      //   value: GetInventoryBatchesSort.Vatid,
      // },
      // {
      //   field: 'vatid',
      //   sort: 'desc',
      //   title: t('columns.vatid'),
      //   type: 'text',
      //   value: GetInventoryBatchesSort.Vatid2,
      // },
      // {
      //   field: 'warehousebatchitemid',
      //   sort: 'asc',
      //   title: t('columns.warehousebatchitemid'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Warehousebatchitemid,
      // },
      // {
      //   field: 'warehousebatchitemid',
      //   sort: 'desc',
      //   title: t('columns.warehousebatchitemid'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Warehousebatchitemid2,
      // },
      // {
      //   field: 'warehouseitemid',
      //   sort: 'asc',
      //   title: t('columns.warehouseitemid'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Warehouseitemid,
      // },
      // {
      //   field: 'warehouseitemid',
      //   sort: 'desc',
      //   title: t('columns.warehouseitemid'),
      //   type: 'number',
      //   value: GetInventoryBatchesSort.Warehouseitemid2,
      // },
    ],
    [props.shouldShowCustomFields, t],
  )

  return sortItems
}
