import { Avatar, Box, Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { AllPaymentTypesQuery, PaymentType } from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { PaymentMethodIcon } from './payment-method-icon'

export const PaymentMethodRenderer = (
  params: GridRenderCellParams<
    ArrayElement<AllPaymentTypesQuery['allPaymentTypes']>
  >,
) => <PaymentMethod paymentOption={params.row} />

type PaymentMethodProps = {
  paymentOption: PaymentType
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const { t } = useTranslation(['payment_options'])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Avatar
          variant="square"
          sx={{
            width: 30,
            height: 30,
            background: 'white',
          }}
        >
          <PaymentMethodIcon
            paymentOption={props.paymentOption}
            fontSize="medium"
          />
        </Avatar>
      </Box>
      <Stack direction="column" spacing={0} ml={1}>
        <Typography>
          {t(
            `payment_options:payment_method.${props.paymentOption.paymentmethodid}`,
          )}
        </Typography>
        {/* TODO: Implement payment type status */}
        {/* <Typography variant="caption">{'Status: Unknown'}</Typography> */}
      </Stack>
    </Box>
  )
}
