import { useMutation, useQuery } from '@apollo/client'
import { SectionHeader } from '@sitoo/mui-components'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { CountriesInput } from '../../../inputs/countries-input'
import { FieldHighlight } from '../../../components/field-highlight'
import { KeyValueInput } from '../../../inputs/key-value-input'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { StoreTagsInput } from '../../../inputs/store-tags-input'
import { StoresInput } from '../../../inputs/stores-input'
import {
  AddUpdateSettingsStoreDocument,
  AllStoreTagsDocument,
  DeleteSettingsStoreDocument,
  GetStoresDocument,
  SettingsStoreDocument,
  SettingsStoreQuery,
  SettingsStoresDocument,
  StoreType,
  WarehouseUseType,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useScrollToError } from '../../../hooks/scroll-to-error'
import { validateCoordinate } from '../../../utils/validate'
import { SelectInput } from '../../../inputs/select-input'
import { TextFieldInput } from '../../../inputs/text-field-input'

type Store = Omit<
  SettingsStoreQuery['store'],
  | 'nearby_stores'
  | 'usetype_movein'
  | 'usetype_moveout'
  | 'usetype_manualin'
  | 'usetype_manualout'
  | 'usetype_shipmentin'
  | 'usetype_shipmentout'
> & {
  nearby_stores?: { id: number }[]

  usetype_movein?: WarehouseUseType | ''
  usetype_moveout?: WarehouseUseType | ''
  usetype_manualin?: WarehouseUseType | ''
  usetype_manualout?: WarehouseUseType | ''
  usetype_shipmentin?: WarehouseUseType | ''
  usetype_shipmentout?: WarehouseUseType | ''
}

export const StoreDialog = () => {
  const { t } = useTranslation(['shared', 'settings', 'stores', 'warehouses'])

  const { id } = useParams()
  const storeId = Number(id)
  const isNewStore = !storeId

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(SettingsStoreDocument, {
    variables: { storeId: Number(storeId) },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  })

  const [addUpdateStoreMutation, { loading: addLoading }] = useMutation(
    AddUpdateSettingsStoreDocument,
    { refetchQueries: [SettingsStoresDocument, AllStoreTagsDocument] },
  )

  const { data: storesData, loading: fetchStoresLoading } =
    useQuery(GetStoresDocument)

  const [deleteStoreMutation, { loading: deleteLoading }] = useMutation(
    DeleteSettingsStoreDocument,
    { refetchQueries: [SettingsStoresDocument] },
  )

  const isLoading =
    fetchLoading || fetchStoresLoading || addLoading || deleteLoading

  const storeType = useMemo(() => {
    return Object.values(StoreType).map((value) => ({
      value,
      name: t(`stores:store_type.${value}`),
    }))
  }, [t])

  const warehouseUseType = useMemo(() => {
    return Object.values(WarehouseUseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_use_type.${value}`),
    }))
  }, [t])

  const { reset, control, handleSubmit, watch, getValues, formState } =
    useForm<Store>({
      defaultValues: {
        name: '',
        phone: '',
        email: '',
        externalid: '',
        address: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        externalgroupid: '',
        latitude: '',
        longitude: '',
        storetype: StoreType.Store,
        usetype_movein: '',
        usetype_moveout: '',
        usetype_manualin: '',
        usetype_manualout: '',
        usetype_shipmentin: '',
        usetype_shipmentout: '',
      },
    })

  const onClose = () => {
    navigate(generatePath(RootRoute.SettingsStores))
  }

  const usageTypeNames = [
    'usetype_movein',
    'usetype_moveout',
    'usetype_manualin',
    'usetype_manualout',
    'usetype_shipmentin',
    'usetype_shipmentout',
  ] as const

  const nameRef = useRef<HTMLDivElement>(null)
  const countryRef = useRef<HTMLDivElement>(null)

  useScrollToError({ ref: nameRef, name: 'name', control })
  useScrollToError({ ref: countryRef, name: 'countryid', control })

  const onSubmit = async (store: Store) => {
    const { id, nearby_stores, ...fields } = store
    const additionalData =
      store.additional_data && Object.keys(store.additional_data).length
        ? store.additional_data
        : undefined

    await addUpdateStoreMutation({
      variables: {
        store: {
          ...fields,
          nearby_stores: nearby_stores?.map(({ id }) => id),
          storeid: store.id,
          phone: store.phone || undefined,
          email: store.phone || undefined,
          externalid: store.externalid || undefined,
          externalgroupid: store.externalgroupid || undefined,
          latitude: store.latitude || undefined,
          longitude: store.longitude || undefined,
          usetype_movein: store.usetype_movein || undefined,
          usetype_moveout: store.usetype_moveout || undefined,
          usetype_manualin: store.usetype_manualin || undefined,
          usetype_manualout: store.usetype_manualout || undefined,
          usetype_shipmentin: store.usetype_shipmentin || undefined,
          usetype_shipmentout: store.usetype_shipmentout || undefined,
          additional_data: additionalData,
        },
      },
    })
  }

  const deleteItem = async () => {
    if (storeId) {
      await deleteStoreMutation({ variables: { storeId } })
    }
  }

  useEffect(() => {
    if (!storeId) {
      reset()
    }

    if (data?.store) {
      reset({
        ...data.store,
        email: data.store.email || '',
        phone: data.store.phone || '',
        address: data.store.address || '',
        address2: data.store.address2 || '',
        city: data.store.city || '',
        zip: data.store.zip || '',
        state: data.store.state || '',
        externalid: data.store.externalid || '',
        externalgroupid: data.store.externalgroupid || '',
        latitude: data.store.latitude || '',
        longitude: data.store.longitude || '',

        nearby_stores: data.store.nearby_stores?.map((id) => ({ id })),

        usetype_movein: data.store.usetype_movein || '',
        usetype_moveout: data.store.usetype_moveout || '',
        usetype_manualin: data.store.usetype_manualin || '',
        usetype_manualout: data.store.usetype_manualout || '',
        usetype_shipmentin: data.store.usetype_shipmentin || '',
        usetype_shipmentout: data.store.usetype_shipmentout || '',
      })
    }
  }, [data, reset, storeId])

  return (
    <SettingsDetailDialog
      type={isNewStore ? 'add' : 'edit'}
      dialogName="store-list"
      typeLabel={t('settings:store.item_type')}
      getItemName={() => getValues('name')}
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.general_settings')}
      </SectionHeader>

      <SelectInput
        name="storetype"
        control={control}
        options={storeType}
        label={t('settings:store.store_type')}
        dataTestid="store-type"
      />

      <TextFieldInput
        name="name"
        innerRef={nameRef}
        control={control}
        label={t('settings:store.name')}
        sx={{ mb: 2 }}
        required
        rules={{ required: true }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-name' },
        }}
      />

      <TextFieldInput
        name="externalid"
        control={control}
        label={t('settings:store.external_id')}
        sx={{ mb: 2 }}
        helperText={t('settings:store.external_id_helper')}
        slotProps={{
          htmlInput: { 'data-testid': 'store-externalid' },
        }}
      />

      {!isLoading && !watch('externalid') && (
        <FieldHighlight
          label={t('shared:validation.external_id_warning')}
          sx={{ mt: -1, mb: 2 }}
          dataTestId="no-external-id-highlight"
        />
      )}

      <TextFieldInput
        name="externalgroupid"
        control={control}
        label={t('settings:store.external_group_id')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-externalgroupid' },
        }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.contact_information')}
      </SectionHeader>

      <TextFieldInput
        name="phone"
        control={control}
        label={t('settings:store.phone')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-phone' },
        }}
      />

      <TextFieldInput
        name="email"
        control={control}
        label={t('settings:store.email')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-email' },
        }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.location')}
      </SectionHeader>

      <TextFieldInput
        name="address"
        control={control}
        label={t('settings:store.address')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-address' },
        }}
      />

      <TextFieldInput
        name="address2"
        control={control}
        label={t('settings:store.address2')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-address2' },
        }}
      />

      <TextFieldInput
        name="zip"
        control={control}
        label={t('settings:store.zip')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-zip' },
        }}
      />

      <TextFieldInput
        name="city"
        control={control}
        label={t('settings:store.city')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-city' },
        }}
      />

      <TextFieldInput
        name="state"
        control={control}
        label={t('settings:store.state')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-state' },
        }}
      />

      <CountriesInput
        innerRef={countryRef}
        name="countryid"
        control={control}
        isRequired
        sx={{ mb: 2 }}
      />

      <TextFieldInput
        name="latitude"
        control={control}
        label={t('settings:store.latitude')}
        sx={{ mb: 2 }}
        rules={{
          validate: (value) => {
            if (value && !validateCoordinate(value)) {
              return t('settings:store.validation.coordinate')
            }
          },
        }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-latitude' },
        }}
      />

      <TextFieldInput
        name="longitude"
        control={control}
        label={t('settings:store.longitude')}
        sx={{ mb: 2 }}
        rules={{
          validate: (value) => {
            if (value && !validateCoordinate(value)) {
              return t('settings:store.validation.coordinate')
            }
          },
        }}
        slotProps={{
          htmlInput: { 'data-testid': 'store-longitude' },
        }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.nearby_stores')}
      </SectionHeader>

      <StoresInput
        stores={storesData?.stores || []}
        isLoading={fetchStoresLoading}
        name="nearby_stores"
        control={control}
        multiple
        sx={{ '.MuiFormControl-root': { mb: 2 } }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.default_warehouse_use_type')}
      </SectionHeader>

      {usageTypeNames.map((name) => (
        <SelectInput
          name={name}
          key={name}
          control={control}
          options={warehouseUseType}
          label={t(`settings:store.${name}`)}
          dataTestid="warehouse-type"
        />
      ))}

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.store_tags')}
      </SectionHeader>

      <StoreTagsInput
        name="tags"
        freeSolo
        control={control}
        helperText={t('settings:store.store_tags_description')}
        sx={{ '.MuiFormControl-root': { mb: 2 } }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:store.extra_settings')}
      </SectionHeader>

      <KeyValueInput name="additional_data" control={control} />
    </SettingsDetailDialog>
  )
}
