import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GetStoresDocument } from '../../generated/graphql'
import { useFormFilter } from '../../hooks/form-filter'
import { useSelectFirst } from '../../hooks/use-select-first'
import { SelectInput } from '../../inputs/select-input'

export type ExternalStoreIdFilter = {
  storeId?: string
}

export const ExternalStoreIdFilter = () => {
  const { t } = useTranslation(['shared', 'store_dashboards'])

  const { data, loading: isLoading } = useQuery(GetStoresDocument)

  const options = useMemo(() => {
    return (
      data?.stores
        .filter((store) => store.externalid && store.warehouses?.length)
        .map((store) => ({
          value: String(store.externalid),
          name: `${store.name} (${store.externalid})`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    )
  }, [data?.stores])

  const { formContext } = useFormFilter<ExternalStoreIdFilter>({
    formProps: {
      defaultValues: { storeId: '' },
    },
  })

  useSelectFirst({ formContext, isLoading, options, name: 'storeId' })

  return (
    <SelectInput
      name="storeId"
      control={formContext.control}
      options={options}
      disabled={isLoading}
      label={t('store_dashboards:store_label')}
    />
  )
}
