import { useQuery } from '@apollo/client'
import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../../components/data-grid/context'
import { GetPriceListsDocument } from '../../../../generated/graphql'
import { useStateParams } from '../../../../hooks/state-params'
import { useTracking } from '../../../../hooks/tracking'
import {
  DEFAULT_PRICELIST_ID,
  MAX_NUM_REQUEST,
} from '../../../../utils/constants'

type QueryParamState = {
  pricelistId?: string
}

export const PricelistFilter = () => {
  const { t } = useTranslation(['prices'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const { data: pricelistsData, loading: isLoading } = useQuery(
    GetPriceListsDocument,
    {
      variables: { num: MAX_NUM_REQUEST, start: 0 },
    },
  )
  const priceLists = pricelistsData?.priceLists.items
  const filterKey = 'pricelistId'
  const currencyFilterKey = 'pricelistCurrency'

  const [queryParams, setQueryParams] = useStateParams<QueryParamState>()

  const pricelistId =
    (priceLists?.some((x) => String(x.id) === queryParams[filterKey])
      ? queryParams[filterKey]
      : String(DEFAULT_PRICELIST_ID)) || String(DEFAULT_PRICELIST_ID)

  useEffect(() => {
    registerFilter([
      {
        key: filterKey,
        isReady: !isLoading,
      },
      {
        key: currencyFilterKey,
        isReady: !isLoading,
      },
    ])
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (!priceLists) return

    if (Number(pricelistId) === DEFAULT_PRICELIST_ID) {
      const pricelistName =
        priceLists?.find((x) => x.id === DEFAULT_PRICELIST_ID)?.pricelistname ||
        ''

      removeFilter(filterKey)

      setFilter(currencyFilterKey, {
        label: t('prices:currency_label'),
        labelValues: [pricelistName],
        isDefault: true,
        value: '',
      })
    } else {
      const pricelist = priceLists?.find((x) => String(x.id) === pricelistId)

      setFilter(filterKey, {
        label: t('prices:price_list_label'),
        labelValues: [pricelist?.pricelistname || ''],
        value: String(pricelistId),
      })

      setFilter(currencyFilterKey, {
        label: t('prices:currency_label'),
        labelValues: [pricelist?.currencycode || ''],
        value: pricelist?.currencycode,
      })
    }
  }, [pricelistId, priceLists, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey || key === currencyFilterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [priceLists, setFilter, setQueryParams, subscribeOnResetFilter])

  return (
    <TextField
      onChange={(event) =>
        setQueryParams({
          [filterKey]:
            event.target.value === String(DEFAULT_PRICELIST_ID)
              ? undefined
              : event.target.value,
        })
      }
      value={priceLists?.length ? pricelistId : ''}
      data-testid="pricelist-select-button"
      select
      label={t('prices:price_list_label')}
    >
      <MenuItem sx={{ display: 'none' }} />
      {priceLists?.map((x) => (
        <MenuItem
          key={x.id}
          value={String(x.id)}
          divider
          onClick={trackButtonClickEvent({
            name: 'price-list-filter-select-price-list',
            id: x.id,
            priceListName: x.pricelistname,
          })}
          data-testid={`pricelist-filter-button-${x.id}`}
        >
          <Typography variant="body02">
            {`${x.pricelistname}${
              x.currencycode ? ` (${x.currencycode})` : ''
            }`}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
