import { useMemo } from 'react'
import {
  GetConfigVarsDocument,
  GetWarehouseBatchStatusDocument,
} from '../../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

type Value = {
  value: number
  label: string
}

type Deviation = Value & {
  annotation: string
}

export const useBatchStatus = (
  warehouseBatchId: number,
  warehouseId: number,
) => {
  const { t } = useTranslation(['shared', 'stocktaking'])
  const { data: configVarsData } = useQuery(GetConfigVarsDocument)
  const includeNoStockValue =
    configVarsData?.configVars.stockValueWarning ?? false

  const {
    data: warehouseBatchSummaryData,
    loading,
    refetch,
  } = useQuery(GetWarehouseBatchStatusDocument, {
    variables: {
      warehouseBatchId,
      warehouseId,
      includeNoStockValue,
    },
    skip: !configVarsData,
  })

  const {
    num_stocktaking_no_stock_value: numStocktakingNoStockValue,
    num_stocktaking_transaction_changes: numStocktakingTransactionChanges,
    num_items: numItems,
    num_stocktaking_not_counted: numStocktakingNotCounted,
  } = warehouseBatchSummaryData?.warehouseBatchStatus ?? {}

  const deviations = useMemo(() => {
    const deviations: Deviation[] = []
    if (numStocktakingNoStockValue && numStocktakingNoStockValue > 0) {
      deviations.push({
        annotation: t('stocktaking:finish_dialog.annotation_no_manual_in', {
          count: numStocktakingNoStockValue,
        }),
        value: numStocktakingNoStockValue,
        label: t('stocktaking:finish_dialog.counted_no_stock_value'),
      })
    }
    if (
      numStocktakingTransactionChanges &&
      numStocktakingTransactionChanges > 0
    ) {
      deviations.push({
        annotation: t('stocktaking:finish_dialog.annotation_modified_after', {
          count: numStocktakingTransactionChanges,
        }),
        value: numStocktakingTransactionChanges,
        label: t('stocktaking:finish_dialog.modified_after'),
      })
    }
    return deviations
  }, [numStocktakingNoStockValue, numStocktakingTransactionChanges, t])

  const values: Value[] = [
    {
      label: t('stocktaking:finish_dialog.count'),
      value: numItems ?? 0,
    },
    {
      label: t('stocktaking:finish_dialog.uncounted_with_stock_value'),
      value: numStocktakingNotCounted ?? 0,
    },
  ]

  return {
    deviations,
    loading,
    refetch,
    values,
  }
}
