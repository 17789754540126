import { useQuery } from '@apollo/client'
import { Button, Paper } from '@mui/material'
import { PaymentCustomIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '../../..'
import { ViewPanel, ViewPanelHeader } from '../../../../components/view-panel'
import { GetPaymentTypeDocument } from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { useTracking } from '../../../../hooks/tracking'
import { Information } from './information'
import { Method } from './method'

type Props = {
  id?: number
  open: boolean
  onClose: () => void
}

export const PaymentOptionsViewPanel = (props: Props) => {
  const { t } = useTranslation(['payment_options'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()

  const { data, loading: isLoading } = useQuery(GetPaymentTypeDocument, {
    variables: { id: Number(props.id) },
    skip: !props.id,
    fetchPolicy: 'cache-and-network',
  })

  const name = 'payment-options'

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={data?.getPaymentType.name || ''}
        section={t('payment_options:page_title')}
        onClose={props.onClose}
        actions={
          props.id && (
            <Button
              component={Link}
              to={generatePath(RootRoute.SettingsPaymentOption, {
                id: props.id,
              })}
              color="secondary"
              size="small"
              data-testid="edit-payment-option-button"
              onClick={() => {
                trackButtonClick({
                  name: `${name}-edit`,
                  id: props.id,
                })
              }}
            >
              {t('shared:action.edit')}
            </Button>
          )
        }
        sectionIcon={<PaymentCustomIcon />}
      />

      <Paper elevation={0} sx={{ mt: 1 }}>
        <Information
          paymentOption={data?.getPaymentType}
          isLoading={isLoading}
        />

        <Method isLoading={isLoading} paymentOption={data?.getPaymentType} />
      </Paper>
    </ViewPanel>
  )
}
