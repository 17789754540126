import { Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { CancelStocktakingDialog } from '../stocktaking/cancel-stocktaking-dialog'
import { ImportStocktakingDialog } from '../stocktaking/import-stocktaking-dialog'
import { ExportStockDialog } from './export-stocktaking-dialog'

type Props = {
  anchorEl: Element | null
  isOpen: boolean
  onClose(): void
  warehouseId: number
  warehouseBatchId: number
}

export const ExportContextMenu = ({
  warehouseId,
  warehouseBatchId,
  anchorEl,
  onClose,
  isOpen,
}: Props) => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openImportDialog, setOpenImportDialog] = useState(false)
  const [currentExport, setCurrentExport] = useState<
    'all' | 'filtered' | undefined
  >()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { t } = useTranslation(['shared', 'stocktaking', 'products'])
  const filterContext = useFilterContext()

  return (
    <>
      <Menu
        id="stocktaking-more-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'stocktaking-more-menu-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenImportDialog(true)
            onClose()
          }}
          data-testid="stocktaking-more-menu-import-items"
        >
          <Typography variant="body02">
            {t('stocktaking:import_items')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentExport('all')
            onClose()
          }}
          data-testid="stocktaking-more-menu-import-items"
        >
          <Typography variant="body02">
            {t('stocktaking:export_all')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCurrentExport('filtered')
            onClose()
          }}
          data-testid="stocktaking-more-menu-import-items"
        >
          <Typography variant="body02">
            {t('stocktaking:export_filtered')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenCancelDialog(true)
            onClose()
          }}
          data-testid="stocktaking-more-menu-cancel"
        >
          <Typography variant="body02">{t('shared:action.cancel')}</Typography>
        </MenuItem>
      </Menu>
      <ImportStocktakingDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
        onSuccess={() => {
          filterContext.refetch()
        }}
      />

      <CancelStocktakingDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onSuccess={() => {
          navigate(generatePath(RootRoute.Stocktakings2))
          setOpenCancelDialog(false)
        }}
      />
      <ExportStockDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={Boolean(currentExport)}
        onClose={() => {
          setCurrentExport(undefined)
        }}
        exportType={currentExport}
      />
    </>
  )
}
