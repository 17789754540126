import { Maybe } from '../../../generated/graphql'
import { CaptionRendererValue } from './caption-renderer'

type Row = {
  title?: Maybe<string>
  variant?: Maybe<{ name?: Maybe<string>; value?: Maybe<string> }[]>
  manufacturer?: Maybe<{ name?: Maybe<string> }>
}
/**
 * Formats the product title and caption for the CaptionRenderer in the data
 * grid. It also includes the manufacturer and the variants of the product.
 */
export const formatProductTitle = (
  _value: unknown,
  row: Row,
): CaptionRendererValue => {
  const variants = row.variant
    ?.map(({ name, value }) => [name, value].filter(Boolean).join(': '))
    .join(', ')

  const manufacturer = row.manufacturer?.name
    ? `(${row.manufacturer.name})`
    : ''

  return {
    title: row.title,
    caption: [variants, manufacturer].join(' '),
  }
}
