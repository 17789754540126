import { useQuery } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../components/data-grid'
import { ColumnProps } from '../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../components/data-grid/utils/relative-date-renderer'
import {
  AllWarehouseBatchesQuery,
  WarehouseBatchTransaction,
  AllWarehouseBatchesDocument,
  WarehouseBatchState,
} from '../../generated/graphql'
import { useLocalizedDate } from '../../hooks/localized-date'
import { ArrayElement } from '../../utils/types'
import { formatUserDisplayName } from '../../utils/format/display-name'
import { useSnackbar } from 'notistack'
import { getErrorMessages } from '../../utils/error-mapping'
import { CursorPagination } from '../../components/data-grid/cursor-pagination'

type Row = ArrayElement<
  AllWarehouseBatchesQuery['allWarehouseBatches']['items']
>

type Props = {
  type: WarehouseBatchTransaction
  onRowClick: (warehouseId: number, warehouseBatchId: number) => void
}

const PAGE_SIZE = 100

export const WarehouseBatchesList = (props: Props) => {
  const { t } = useTranslation('stocktaking')

  const typeTitle = t(`list.${props.type}`)

  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const { enqueueSnackbar } = useSnackbar()

  const apiRef = useGridApiRef()

  const { data, loading, fetchMore } = useQuery(AllWarehouseBatchesDocument, {
    variables: {
      start: 0,
      num: PAGE_SIZE,
      batchState: WarehouseBatchState.Open,
      transactionType: props.type,
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      const errorMessage = getErrorMessages(error)[0]
      enqueueSnackbar(errorMessage, { variant: 'error' })
    },
  })

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'warehousebatchid',
        minWidth: 80,
        headerName: t('list.id'),
      },
      {
        field: 'datecreated',
        ...ColumnProps.date,
        headerName: t('list.date_created'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'user',
        headerName: t('list.user'),
        minWidth: 160,
        valueGetter: (_value, row): string => {
          return formatUserDisplayName(row.user) || row.emailowner || ''
        },
      },
      {
        field: 'store',
        minWidth: 200,
        headerName: t('list.store'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.store?.name || ''
        },
      },
      {
        field: 'warehouse',
        minWidth: 200,
        headerName: t('list.warehouse'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.name || ''
        },
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  const rows = data?.allWarehouseBatches.items

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.allWarehouseBatches.totalcount) {
      return fetchMore({
        variables: {
          start: (data.allWarehouseBatches?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <DataGrid
      name="warehouse-batches-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={rows}
      rowCount={data?.allWarehouseBatches.totalcount || 0}
      getRowId={(row) => row.warehousebatchid}
      loading={loading}
      fetchMore={fetchMoreItems}
      slots={{ pagination: CursorPagination }}
      noRowsOverlay={{
        icon: <WarehouseIcon />,
        title: t('list.empty_title', { type: typeTitle }),
        description: t('list.empty_description', { type: typeTitle }),
      }}
      disableColumnMenu
      disableAllFilters
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      columnVisibilityModel={{ warehousebatchid: false }}
      onRowClick={(params) => {
        const row = params.row as Row
        if (row.warehouseid && row.warehousebatchid) {
          props.onRowClick(row.warehouseid, row.warehousebatchid)
        }
      }}
      showMore={
        Number(data?.allWarehouseBatches.items?.length) <
        Number(data?.allWarehouseBatches.totalcount)
      }
    />
  )
}
