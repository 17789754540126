import { useMutation } from '@apollo/client'
import { Box, Button, Divider, ListItemText } from '@mui/material'
import { FormFieldset, Link } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import {
  ShipmentStateV2,
  UpdateShipmentV2Document,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useScrollToError } from '../../../../hooks/scroll-to-error'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { EditShipmentFormContext } from '../../edit-shipment'
import { SetCarrierDialog } from '../../set-carrier-dialog'
import { SetStateDialog } from '../../set-state-dialog'
import { CarrierFields } from '../../shared'

export const EditInformation = () => {
  const { formState, watch, control } =
    useFormContext<EditShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared'])
  const [showCarrierDialog, setShowCarrierDialog] = useState(false)
  const [showSetStateDialog, setShowSetStateDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [updateShipment] = useMutation(UpdateShipmentV2Document)
  const { formatDate } = useLocalizedDate()

  const shipment = watch()

  const carrier = watch('carrier')

  const hasCarrier = (carrier?: CarrierFields | null) =>
    Object.values(carrier ?? []).length > 0

  const carrierRef = useRef<HTMLLIElement>(null)

  useScrollToError({ ref: carrierRef, name: 'carrier', control })

  if (!shipment?.shipmentid) return

  const submitCarrier = async (newCarrier: CarrierFields) => {
    if (shipment.shipmentid) {
      await updateShipment({
        variables: {
          shipment: { ...newCarrier, shipmentid: shipment.shipmentid },
        },
      })
    }

    enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    setShowCarrierDialog(false)
  }
  const allItemsReceived = shipment.packages
    ? !shipment.packages.some(
        (pkg) => pkg.totalQuantity !== pkg.totalReceivedQuantity,
      )
    : false

  return (
    <FormFieldset label={t('shipments_v2:shipment_form.general_fieldset')}>
      <Box sx={{ my: -2 }}>
        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <>
              {shipment?.shipmentstate &&
              [ShipmentStateV2.Cancelled, ShipmentStateV2.Received].includes(
                shipment.shipmentstate,
              ) ? (
                <ListItemText
                  primary={t(
                    `shipments_v2:shipment_state.${shipment.shipmentstate}`,
                  )}
                />
              ) : (
                <>
                  <SetStateDialog
                    open={showSetStateDialog}
                    state={shipment?.shipmentstate ?? undefined}
                    shipmentId={shipment.shipmentid}
                    onClose={() => setShowSetStateDialog(false)}
                    onSuccess={() => {
                      enqueueSnackbar(
                        t('shipments_v2:shipment_message.success_update'),
                      )
                      setShowSetStateDialog(false)
                    }}
                    onError={(errorMessage) => {
                      enqueueSnackbar(errorMessage, { variant: 'error' })
                    }}
                  />
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => setShowSetStateDialog(true)}
                  >
                    {t('shared:action.edit')}
                  </Button>
                </>
              )}
            </>
          }
        >
          <ListItemText
            primary={t(`shipments_v2:shipment_form.state`)}
            secondary={
              shipment?.shipmentstate &&
              ![ShipmentStateV2.Received, ShipmentStateV2.Cancelled].includes(
                shipment.shipmentstate,
              )
                ? t(
                    `shipments_v2:shipment_state.${shipment.shipmentstate || ''}`,
                  )
                : ''
            }
          />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText
              primary={shipment.datenew ? formatDate(shipment.datenew) : ''}
            />
          }
        >
          <ListItemText primary={t(`shipments_v2:shipment_form.created`)} />
        </ListItemSkeleton>

        {shipment.shipmentstate &&
          [
            ShipmentStateV2.InTransit,
            ShipmentStateV2.Received,
            ShipmentStateV2.Cancelled,
          ].includes(shipment.shipmentstate) && (
            <>
              <Divider sx={{ mx: -2 }} />
              <ListItemSkeleton
                childrenSkeleton
                sx={{
                  px: 0,
                }}
                secondaryAction={
                  <ListItemText
                    primary={t(
                      allItemsReceived ? 'shared:label.yes' : 'shared:label.no',
                    )}
                  />
                }
              >
                <ListItemText
                  primary={t(`shipments_v2:shipment_form.all_items_received`)}
                />
              </ListItemSkeleton>
            </>
          )}

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
            whiteSpace: 'pre-line',
          }}
          secondaryAction={
            <>
              <SetCarrierDialog
                action={hasCarrier(carrier) ? 'edit' : 'add'}
                data={{ comment: shipment.comment, ...carrier }}
                open={showCarrierDialog}
                onClose={() => setShowCarrierDialog(false)}
                onSuccess={() => setShowCarrierDialog(false)}
                onSubmit={submitCarrier}
                onError={(error) => {
                  const errorMessage = getErrorMessage(
                    error,
                    'shipments',
                    t('shipments_v2:shipment_message.failure_update'),
                  )

                  enqueueSnackbar(errorMessage, { variant: 'error' })
                }}
              />
              {shipment.shipmentstate &&
                ![ShipmentStateV2.Received, ShipmentStateV2.Cancelled].includes(
                  shipment.shipmentstate,
                ) && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowCarrierDialog(true)
                    }}
                  >
                    {hasCarrier(carrier)
                      ? t('shared:action.edit')
                      : t('shipments_v2:shipment_form.add_carrier')}
                  </Button>
                )}
            </>
          }
        >
          <ListItemText
            ref={carrierRef}
            primary={t(`shipments_v2:shipment_form.carrier`)}
            sx={{
              ...(formState.errors.carrier
                ? {
                    color: (theme) => theme.palette.error.main,
                  }
                : {}),
            }}
            secondaryTypographyProps={{
              component: 'div',
              sx: {
                '&.MuiListItemText-secondary': {
                  ...(formState.errors.carrier
                    ? {
                        color: (theme) => theme.palette.error.main,
                      }
                    : {}),
                },
              },
            }}
            secondary={
              <>
                <div>{carrier?.carrier_name}</div>
                {carrier?.carrier_trackingurl && (
                  <Link
                    href={carrier.carrier_trackingurl || ''}
                    target="_blank"
                  >
                    {carrier?.carrier_trackingurl}
                  </Link>
                )}
              </>
            }
          />
        </ListItemSkeleton>
      </Box>
    </FormFieldset>
  )
}
