import { LoadingButton } from '@mui/lab'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FileType } from '../../generated/graphql'
import { Controller, useForm } from 'react-hook-form'
import { useTracking } from '../../hooks/tracking'
import { useMemo } from 'react'

type Props = {
  label: string
  open: boolean
  onClose?(): void
  onSuccess?(fileType: FileType): void | Promise<void>
  isLoading?: boolean
}

export const ExportDialog = (props: Props) => {
  const { t } = useTranslation(['shared'])
  const { trackButtonClick } = useTracking()

  const { control, getValues } = useForm({
    defaultValues: { type: FileType.XLSX },
  })

  const onClose = () => {
    trackButtonClick({ name: 'export-dialog-close' })
    props.onClose?.()
  }

  const onSuccess = () => {
    const type = getValues('type')

    trackButtonClick({ name: 'export-dialog-success', type })
    void props.onSuccess?.(type)
  }

  const options = useMemo(() => [FileType.XLSX, FileType.CSV], [])

  return (
    <Dialog
      data-testid="export-dialog"
      open={props.open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle data-testid="export-dialog-header" type="extended">
        {props.label}
      </DialogTitle>

      <DialogContent>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <>
              <InputLabel>{t('shared:label.type')}</InputLabel>

              <Select
                data-testid="file-format-selector"
                value={field.value}
                onChange={(event) => field.onChange(event.target.value)}
                sx={{ mb: 2, width: 388 }}
              >
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button data-testid="cancel-button" color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>

        <LoadingButton
          data-testid="export-button"
          loading={props.isLoading}
          onClick={onSuccess}
        >
          {t('shared:action.export')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
